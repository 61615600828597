import React from "react";

const PillElement = ({
  color,
  text,
  icon,
  iconPosition = false,
  onClick,
  id,
  iconWidth,
  iconSize,
  textColor,
  width,
}) => {
  return (
    <div
      className={`pill-element pointer`}
      style={{ backgroundColor: color, width: width ? width : "none" }}
      onClick={() => (onClick ? onClick() : console.log(text))}
      id={id}
    >
      {iconPosition === true ? (
        <div className="icon-circle">
          <img
            className="btn-icon"
            style={{ width: iconSize ? iconSize : "auto" }}
            src={icon}
            alt="icon"
          />
        </div>
      ) : (
        <></>
      )}
      <p
        style={{ color: textColor ? textColor : "white" }}
        className={`pill-element_text ${
          iconPosition === true ? "pill-r" : "pill-l"
        }`}
      >
        {text}
      </p>
      {iconPosition !== true ? (
        <div
          className="icon-circle"
          style={{ width: iconWidth ? iconWidth : "45px" }}
        >
          <img
            className="btn-icon"
            style={{ width: iconSize ? iconSize : "20px" }}
            src={icon}
            alt="icon"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PillElement;
