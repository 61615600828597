import React from "react";
import { Column } from "primereact/column";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import LaddaButton from "../buttons/laddaButton";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../constants";
import { getCurrentMonthYear } from "../../utils/dateUtils";
import { useDispatch } from "react-redux";
import { setBreadCrumData } from "../../redux/slices/breadCrumbSlice";
import { parse, getYear, getMonth } from "date-fns";

// ---------------------------- FUNTIONS ----------------------------

const getButtons = (btnsArray, data, onClickFunction, label) => {
  if (!Array.isArray(btnsArray)) {
    throw new Error("El primer parámetro debe ser un array.");
  }
  return btnsArray.map((option) => {
    switch (option.type) {
      case "icon":
        return iconBtnTemplate(data, option);
      case "redirectData":
        return redirecDataBtnTemplate(data, option);
      case "iconSwitch":
        return iconSwitchBtnTemplate(data, option);
      case "button":
        return tableBtnTemplate(data, label, onClickFunction);
      case "dateHideButton":
        return dateHideBtnTemplate(data, option);
      default:
        console.warn(`Opción no reconocida: ${option}`);
        return null;
    }
  });
};

// ---------------------------- COLUMNS TEMPLATES ----------------------------

export const defaultColumn = (item, fulltext) => {
  const truncateText = (text, maxLength = 145) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  
  return (
    <Column
      style={{ width: item?.columnWidth ? item.columnWidth : "auto" }}
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="table-column-container">
            <p className="table-default-column">
              {fulltext === true
                ? rowData[item.field]
                : truncateText(rowData[item.field])}
            </p>
          </div>
        );
      }}
    />
  );
};

export const columnRight = (item) => {
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="table-column-container">
            <p className="table-default-column" style={{ textAlign: "right" }}>
              {truncateText(rowData[item.field])}
            </p>
          </div>
        );
      }}
    />
  );
};

export const hideColumn = (item) => {
  return null;
};

export const urlColumn = (item) => {
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="table-column-container">
            <a
              href={rowData[item.field]}
              className="url-column"
              target="_blank"
            >
              {truncateText(rowData[item.field])}
            </a>
          </div>
        );
      }}
    />
  );
};

export const titlesColumn = (item) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="table-buttons-container">
            <p className="table-titles-column">{rowData[item.field]}</p>
          </div>
        );
      }}
    />
  );
};

export const pillsColumn = (item) => {
  return (
    <Column
      // style={{ display: "flex", justifyContent: 'center' }}
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        console.log("rowData[item.field]", rowData[item.field]);
        return (
          <div className={`pill-buttons-container HContainer j-center`}>
            <div
              className={`pill-table-column ${
                rowData[item.field] === "Nivel Alto" ||
                rowData[item.field] === "Alto"
                  ? "level-redPill level-pill-font"
                  : rowData[item.field] === "Nivel Medio" ||
                    rowData[item.field] === "Medio"
                  ? "level-yellowPill level-pill-font"
                  : rowData[item.field] === "Nivel Bajo" ||
                    rowData[item.field] === "Bajo"
                  ? "level-greenPill level-pill-font"
                  : ""
              }`}
            >
              <p className="pill-table-column-text">{rowData[item.field]}</p>
            </div>
          </div>
        );
      }}
    />
  );
};

export const actionButtonsColumn = (item, buttons, onClickftn, label) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="table-buttons-container">
            {getButtons(buttons, rowData, onClickftn, label)}
          </div>
        );
      }}
    />
  );
};

export const editButtonsColumn = (item, buttons, onClickftn, label, data) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        {
          return rowData.isEditable !== true ? (
            <></>
          ) : (
            <div className="table-buttons-container">
              {getButtons(buttons, rowData, onClickftn, label)}
            </div>
          );
        }
      }}
    />
  );
};

export const actionSelfApraisalButtonsColumn = (
  item,
  buttons,
  onClickftn,
  label,
  hideBtn
) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return rowData.completed !== 0 ? (
          <></>
        ) : hideBtn ? (
          <div className="table-buttons-container">
            {getButtons(buttons, rowData, onClickftn, label)}
          </div>
        ) : null;
      }}
    />
  );
};

export const tooltipColumn = (item, tooltipFragment) => {
  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <StyledTooltip
            title={tooltipFragment && tooltipFragment(rowData[item.field])}
            followCursor
          >
            <div>
              <p className="table-default-column">
                {truncateText(rowData[item.field])}
              </p>
            </div>
          </StyledTooltip>
        );
      }}
    />
  );
};

export const redirectionColumn = (item) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="pill-buttons-container">
            <Link
              className="column-link"
              to={{
                pathname: item.url,
                state: rowData,
              }}
            >
              {rowData[item.field]}
            </Link>
          </div>
        );
      }}
    />
  );
};

export const percentageBtnColumn = (item) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="pill-buttons-container j-center align-center">
            <p
              className={
                rowData[item.field] < 80
                  ? "updown-text-red"
                  : "updown-text-green"
              }
            >
              {rowData[item.field]} %
            </p>

            {rowData[item.field] < 80 ? (
              <div
                style={{ marginLeft: "5px" }}
                className="eye-btn"
                onClick={() => console.log(rowData)}
              >
                <img className="btn-icon" src={icons.comments_icon} alt="eye" />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }}
    />
  );
};

export const upDownNumberColumn = (item) => {
  return (
    <Column
      key={item.field}
      field={item.field}
      header={item.header}
      sortable={item.ordenable}
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
      body={(rowData) => {
        return (
          <div className="pill-buttons-container j-center">
            <p
              className={
                rowData[item.field] < 200000000
                  ? "updown-text-red"
                  : "updown-text-green"
              }
            >
              {rowData[item.field]}
            </p>
            {rowData[item.field] < 200000000 ? (
              <img
                className="btn-icon"
                src={icons.arrow_drop_down}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
            ) : (
              <img
                className="btn-icon"
                src={icons.arrow_drop_up}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export const expanderColumn = (item) => {
  return (
    <Column
      key={item.field}
      expander
      headerClassName={`table-header-styled ${
        item.index === 0 ? "table-header-rounded-left" : ""
      } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
    />
  );
  //   <Column
  //   key={item.field}
  //   field={item.field}
  //   header={item.header}
  //   sortable={item.ordenable}
  //   headerClassName={`table-header-styled ${item.index === 0 ? "table-header-rounded-left" : ""
  //     } ${item.lastItem === true ? "table-header-rounded-right" : ""}`}
  //   body={(rowData) => {
  //     return (
  //       <div className="table-column-container">
  //         <p className="table-default-column">
  //           {truncateText(rowData[item.field])}
  //         </p>
  //       </div>
  //     );
  //   }}
  // />
};

// ---------------------------- BTNS TEMPLATES ----------------------------

const iconBtnTemplate = (rowData, option) => {
  if (!option.icon) {
    console.warn("No icon provided for the button");
  }
  return (
    <div
      className="eye-btn"
      onClick={() =>
        option.onClick ? option.onClick(rowData) : console.log(rowData)
      }
    >
      <img className="btn-icon-table" src={option.icon} alt="icon" />
    </div>
  );
};

const RedirecDataBtn = ({ rowData, option }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { rowData, ...extraData } = location.state || {};

  const handleClick = () => {
    if (
      option.url == "/riskAndOpportunityManagement/riskAdminGestion" ||
      option.url == "/riskAndOpportunityManagement/opportunityAdminGestion"
    ) {
      dispatch(
        setBreadCrumData({ rowData: { ...rowData, ...option.extraData } })
      );
    }
    navigate(option.url, {
      state: { rowData: { ...rowData, ...option.extraData } },
    });
  };

  return (
    <div className="eye-btn" onClick={handleClick}>
      {option.icon ? (
        <img className="btn-icon-table" src={option.icon} alt="icon" />
      ) : (
        console.warn("No icon provided for the button")
      )}
    </div>
  );
};

const redirecDataBtnTemplate = (rowData, option) => {
  return <RedirecDataBtn rowData={rowData} option={option} />;
};

const iconSwitchBtnTemplate = (rowData, option) => {
  if (!option.icon) {
    console.warn("No icon provided for the button");
  }
  var isEditable = getCurrentMonthYear(rowData.year, rowData.month);
  return (
    <div
      className="eye-btn"
      onClick={() =>
        option.onClick ? option.onClick(rowData) : console.log(rowData)
      }
    >
      {isEditable ? (
        <img className="btn-icon-table" src={option.icon} alt="icon" />
      ) : (
        <img className="btn-icon-table" src={option.icon2} alt="icon" />
      )}
    </div>
  );
};

const dateHideBtnTemplate = (rowData, option) => {
  if (!option.icon) {
    console.warn("No icon provided for the button");
  }

  const date = parse(rowData.date, "dd/MM/yyyy", new Date());
  var isEditable = getCurrentMonthYear(getYear(date), getMonth(date) + 1);

  if (isEditable) {
    return (
      <div
        className="eye-btn"
        onClick={() =>
          option.onClick ? option.onClick(rowData) : console.log(rowData)
        }
      >
        <img className="btn-icon-table" src={option.icon} alt="icon" />
      </div>
    );
  } else {
    return;
  }
};

const tableBtnTemplate = (rowData, label, onClickftn) => {
  return (
    <LaddaButton
      variant={"contained"}
      backgroundColor={"#6A6AD8"}
      color={"#FFFF"}
      margin={"7px 5px 0 5px"}
      onClick={() => (onClickftn ? onClickftn(rowData) : console.log(rowData))}
    >
      <p>{label}</p>
    </LaddaButton>
  );
};
// ---------------------------- STYLES ----------------------------

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    color: "#333399",
    fontWeight: 700,
    padding: "15px 20px",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "10px",
    fontFamily: "Lato",
  },
}));
