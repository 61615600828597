import React from "react";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./styles.css";
import PdfViewer from "../pdfViewer";

// Configuración del worker para pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const MediaViewer = ({ src, type }) => {
  const handleContextMenu = (event) => {
    event.preventDefault(); // Evitar que se muestre el menú contextual
  };

  const renderContent = () => {
    switch (type) {
      case "Imagen":
      case "Gif":
        return <img src={src} alt="media" className="media-content" />;
      case "Video":
        return (
          <video controls className="media-content">
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "PDF":
        return (
          <div style={{ height: "inherit", margin: "0 0 50px 0" }}>
            <PdfViewer fileUrl={src} />
          </div>
        );
      default:
        return <p>Unsupported media type</p>;
    }
  };

  return (
    <div onContextMenu={handleContextMenu} className="media-viewer">
      {renderContent()}
    </div>
  );
};

export default MediaViewer;
