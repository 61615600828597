import React, { useEffect, useState } from "react";
import ModalComponent from "../../components/modals";
import LaddaButton from "../../components/buttons/laddaButton";
import { Grid } from "@mui/material";
import "./styles.css";
import { formatTextWithLineBreaks } from "../../utils/utilsFunctions";

const StudyMaterialsModal = ({ open, handleClose, data }) => {
  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title={""}
      actions={
        <div className="HContainer j-center w-complete">
          <LaddaButton variant="contained" onClick={handleClose}>
            <div className="HContainer align-center">
              <p className="button_text">Cerrar</p>
            </div>
          </LaddaButton>
        </div>
      }
    >
      <div className="modalContainer">
        <h1 className="progress-label-title">{data.competence}</h1>
        <Grid
          style={{ width: "750px" }}
          justifyContent="center"
          container
          spacing={1.5}
        >
          <Grid item xs={12} md={12}>
            <div>
              <p className="blueLabelForm">Actividad:</p>
              <p>{data.nameActivity}</p>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <p className="blueLabelForm">Prioridad:</p>
              <p>{data.priorityText}</p>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div>
              <p className="blueLabelForm">Competencia:</p>
              <p>{data.competencyName}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <p className="blueLabelForm">Descripción:</p>
              <p>{formatTextWithLineBreaks(data.description)}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <p style={{ fontWeight: "bold" }}>{data.note}</p>
            </div>
          </Grid>

        </Grid>
      </div>
    </ModalComponent>
  );
};

export default StudyMaterialsModal;
