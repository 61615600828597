import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import LaddaButton from "../../../components/buttons/laddaButton";
import ModalComponent from "../../../components/modals";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../../../components/textField/formTextField";
import ToastNotification from "../../../components/toastNotification";
import { opportunityState } from "../../../constants/selectData";
import SimpleSelect from "../../../components/selects/simpleSelect";
import {
  callApiAsync,
  postUpdateOpportunityDetail,
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  description: yup.string().required("Este campo es obligatorio"),
  title: yup.string().required("Este campo es obligatorio"),
  benefit: yup.string().required("Este campo es obligatorio"),
  actionPlan: yup.string().required("Este campo es obligatorio"),
  impact: yup.string().required("Este campo es obligatorio"),
  opportunityState: yup.string().required("Este campo es obligatorio"),
});

const EditModal = ({
  opportunityData,
  openEditModal,
  handleCloseEditModal,
  getOpportunityData,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    loadData();
  }, [openEditModal]);

  const handleClose = () => {
    clearData();
    reset();
    handleCloseEditModal();
  };

  const loadData = () => {
    setValue("opportunityState", opportunityData?.state);
    setValue("description", opportunityData?.description);
    setValue("benefit", opportunityData?.benefit);
    setValue("actionPlan", opportunityData?.actionPlan);
    setValue("impact", opportunityData?.impact);
    setValue("title", opportunityData?.title);
  };

  const clearData = () => {
    setValue("opportunityState", "");
    setValue("description", "");
    setValue("benefit", "");
    setValue("actionPlan", "");
    setValue("impact", "");
    setValue("title", "");
  };

  const onSubmit = async (formData) => {
    const dataToSave = {
      opportunityMonthId: opportunityData.opportunityMonthId,
      title: formData.title,
      description: formData.description,
      benefit: formData.benefit,
      actionPlan: formData.actionPlan,
      impact: formData.impact,
      opportunityState: formData.opportunityState,
    };
    await updateOpportunity(dataToSave);
  };

  const updateOpportunity = async (dataToSave) => {
    const objRequest = {
      id: dataToSave.opportunityMonthId,
      title: dataToSave.title,
      description: dataToSave.description,
      benefit: dataToSave.benefit,
      actionPlan: dataToSave.actionPlan,
      impact: dataToSave.impact,
      opportunityState: dataToSave.opportunityState,
    };

    callApiAsync(
      () => postUpdateOpportunityDetail(objRequest),
      () => {
        getOpportunityData();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={openEditModal}
        handleClose={handleClose}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer form-modal">
          <Grid container justifyContent="center" spacing={2}>
            <div style={{ marginTop: "25px", width: "90%" }}>
              <Grid item xs={12}>
                <p className="detail-label">Estado:</p>
                <SimpleSelect
                  name="opportunityState"
                  control={control}
                  options={opportunityState}
                  error={errors.opportunityState}
                  hideLabel={true}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label">Titulo de la Oportunidad:</p>
                <FormTextField
                  name="title"
                  control={control}
                  isTextArea={true}
                  error={errors.title}
                  maxLength={500}
                  rows={1}
                  padding={"6.5px"}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label">Descripción:</p>
                <FormTextField
                  name="description"
                  control={control}
                  isTextArea={true}
                  error={errors.description}
                  maxLength={500}
                  rows={3}
                  padding={"6.5px"}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label">Beneficio:</p>
                <FormTextField
                  name="benefit"
                  control={control}
                  isTextArea={true}
                  error={errors.benefit}
                  maxLength={500}
                  rows={3}
                  padding={"6.5px"}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label">Plan de Acción:</p>
                <FormTextField
                  name="actionPlan"
                  control={control}
                  isTextArea={true}
                  error={errors.actionPlan}
                  maxLength={500}
                  rows={3}
                  padding={"6.5px"}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label">Impacto:</p>
                <FormTextField
                  name="impact"
                  control={control}
                  isTextArea={true}
                  error={errors.impact}
                  maxLength={500}
                  rows={3}
                  padding={"6.5px"}
                />
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default EditModal;
