import React, { useState, useEffect } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import ManagmentBanner from "../../components/managmentBanner";
import { styled } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabComponent from "../../components/tabComponent";
import { useLocation } from "react-router-dom";
import TabDetails from "./tabDetails/index";
import TabRegistrationMonth from "./tabRegistrationMonth";
import { formatDate } from "../../utils/dateUtils.js";
import { callApiAsync, getCallOpportunity } from "../../services/apiService.js";
import { isActiveProcessByRole } from "./authorizeFxs.js";
import { useSelector } from "react-redux";

const OpportunityDetails = () => {
  const userData = useSelector((state) => state.userData.userData);

  const location = useLocation();
  const [opportunityData, setOpportunityData] = useState([]);
  const { rowData } = location.state || {};
  const [isEditionAvailable, setisEditionAvailable] = useState(false);

  const getOpportunityData = async () => {
    callApiAsync(
      () => getCallOpportunity(rowData.id),
      (data) => {
        setOpportunityData(data);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        if (data.month !== currentMonth || data.year !== currentYear) {
          setisEditionAvailable(false);
        } else {
          setisEditionAvailable(true);
          var isActiveByProcess = isActiveProcessByRole(
            userData.roleList,
            data.processId
          );
          setisEditionAvailable(isActiveByProcess);
        }
      }
    );
  };

  useEffect(() => {
    getOpportunityData();
  }, []);

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "10px",
    borderTop: `0`,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    fontFamily: "Lato",
  }));

  const tabData = [
    {
      name: "Detalles de la Oportunidad",
      id: "0",
      content: (
        <CustomTabPanel value="0">
          <TabDetails
            data={opportunityData}
            getOpportunityData={getOpportunityData}
            isEditionAvailable={isEditionAvailable}
          />
        </CustomTabPanel>
      ),
    },
    {
      name: "Registro por Mes",
      id: "1",
      content: (
        <CustomTabPanel value="1">
          <TabRegistrationMonth
            dataOpportunity={opportunityData}
            getOpportunityData={getOpportunityData}
            isEditionAvailable={isEditionAvailable}
          />
        </CustomTabPanel>
      ),
    },
  ];

  return (
    <div>
      <BreadCrumbComp />
      <ManagmentBanner />
      <div className="gm-header">
        <div className="HContainer">
          <h1 className="gm-header-title-grey" style={{ marginRight: "15px" }}>
            N°: {opportunityData.code} - {opportunityData.title}
          </h1>
          <h1 className="gm-header-title"> {rowData.processName}</h1>
        </div>
        <div className="gm-header">
          <p className="lastC-text">Última Actualización:</p>
          <p className="li-item">
            {opportunityData
              ? opportunityData?.updateDate
                ? formatDate(opportunityData?.updateDate)
                : ""
              : ""}
          </p>
        </div>
      </div>
      <TabComponent panels={tabData} />
    </div>
  );
};

export default OpportunityDetails;
