import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

const ClientsCard = ({ list, title, url, icon }) => {
  return (
    <div className="client-card-container">
      <div className="header-client-card">
        <div className="HContainer align-center">
          <img
            className="btn-icon"
            src={icon}
            alt="icon"
            style={{ marginRight: "10px" }}
          />
          <h1 className="header-client-title-text">{title}</h1>
        </div>
        <div className="HContainer align-center">
          <Link to={url}  className="no-underline">
            <h1 className="header-client-title-see-more">Ver más</h1>
          </Link>
          <img
            className="btn-icon"
            src={"/icons/chevron.png"}
            alt="icon"
            style={{}}
          />
        </div>
      </div>
      <div className="client-card-body">
        <ul>
          {list.map((x) => {
            return <li className="client-card-list-item">{x}</li>;
          })}
          <li></li>
        </ul>
      </div>
    </div>
  );
};

export default ClientsCard;
