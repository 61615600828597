import React from "react";
import { Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { styled } from "@mui/material/styles";

const FormCalendar = ({
  name,
  control,
  label,
  defaultValue,
  width,
  onChange,
  formLabel,
  error,
}) => {
  const StyledCalendar = styled(({ width, error, ...props }) => (
    <Calendar {...props} baseZIndex={2000} />
  ))(() => ({
    width: width ? width : "auto",
    margin: "0 10px",
    borderRadius: "6px",
    height: "40px",
    borderColor: error ? "red" : undefined,
  }));

  return (
    <div style={{ minWidth: 120 }}>
      {label ? <p className="text-form-label">{label}</p> : <></>}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || null}
        render={({ field }) => (
          <>
            <StyledCalendar
              {...field}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                onChange && onChange(e.value);
              }}
              placeholder={formLabel}
            />
            {error && <p className="error-message">{error.message}</p>}
          </>
        )}
      />
    </div>
  );
};

export default FormCalendar;
