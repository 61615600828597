import { roleByProcess } from "../../constants/enums";

export const filterProcessByRole = (roleList, list) => {
  const filteredValues = Object.keys(roleByProcess)
    .filter((key) => roleList.includes(roleByProcess[key]))
    .map(Number);

  const filteredList = list.filter((item) =>
    filteredValues.includes(item.value)
  );
  return filteredList;
};
