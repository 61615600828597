import React, { useState, useEffect } from "react";
import LaddaButton from "../buttons/laddaButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { changeManagementHeaderBtnState } from "../../redux/slices/navigationSlice";
import { timeFrameState } from "../../constants/enums";
import { getCookie } from "../../utils/getCookie";
import {
  callApiAsync,
  getCallCurrentUser,
  getCallTimeFrameState,
  postCreateDashboard,
  postUpdateTimeFrameState,
} from "../../services/apiService";

const Header = () => {
  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;
  const navigate = useNavigate();

  const [showMenu, setshowMenu] = useState(false);
  const [locationUrl, setlocationUrl] = useState(false);
  const [buttonMenu, setButtonMenu] = useState(false);
  const [frameTimeState, setFrameTimeState] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);

  const location = useLocation();

  const dispatch = useDispatch();

  const showManagementBtn = useSelector(
    (state) => state.navigation.showManagementHeaderBtn
  );

  const fullBtnsUrlArray = ["/"];
  const noBtnsUrlArray = ["/superior_rol_dashboard", "/self_appraisal"];
  const basicBtnsUrlArray = [
    "/dashboard",
    "/clients",
    "/clients/topClients",
    "/clients/topProducts",
    "/biomedicalManagementDashboard",
    "/biomedicalManagementDashboard/indicator",
    "/biomedicalManagementDashboard/manageDevice",
    "/biomedicalManagementDashboard/BioMedicalRequest",
    "/biomedicalManagementDashboard/BioMedicalRequest/createRequest",
    "/biomedicalManagementDashboard/serviceRecords",
    "/riskAndOpportunityManagement",
  ];

  const handleMenuBtn = () => {
    setshowMenu(!showMenu);
  };

  function responsiveHandler() {
    const anchoPantalla = window.innerWidth;
    if (anchoPantalla > 630 && showMenu) {
      setshowMenu(false);
    }
  }

  window.addEventListener("resize", responsiveHandler);
  responsiveHandler();

  // ------------------------ SETTINGS ------------------------

  const getSurveyConfiguration = async () => {
    callApiAsync(getCallTimeFrameState, (data) => {
      setFrameTimeState(data.state);
    });
  };

  const updateTimeFrameState = async () => {
    callApiAsync(postUpdateTimeFrameState);
  };

  const createDashboard = async () => {
    callApiAsync(
      () => postCreateDashboard({ userId: userId }),
      () => {
        navigate("/superior_rol_dashboard");
      }
    );
  };

  const getUserInfo = async () => {
    callApiAsync(
      () => getCallCurrentUser(userId),
      (data) => {
        setCurrentUser(data);
      }
    );
  };

  useEffect(() => {
    getSurveyConfiguration();
    updateTimeFrameState();
    getUserInfo();
  }, []);

  // ------------------------ url watcher ------------------------

  useEffect(() => {
    const verifyUrl = () => {
      const pathActual = location.pathname;
      setlocationUrl(pathActual);
    };
    setButtonMenu(false);
    verifyUrl();
  }, [location]);

  return (
    <>
      <header
        style={{
          display: "flex",
          alignItems: "initial",
          justifyContent: "space-between",
          padding:
            fullBtnsUrlArray.includes(locationUrl) === true
              ? "10px 25px"
              : "10px 0",
          height: "80px",
          width: locationUrl === "/" ? "1100px" : "1020px",
          margin: "auto",
        }}
      >
        {/* ------------------------ LOGO ------------------------ */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <img
              className="logo-icon w-32 h-32"
              src="/images/logo.png"
              alt="logo"
            />
          </Link>
        </div>
        {/* ------------------------ BTN CONTAINER ------------------------ */}
        <div className="button_container HContainer">
          {noBtnsUrlArray.includes(locationUrl) === true ? (
            <></>
          ) : fullBtnsUrlArray.includes(locationUrl) === true ? (
            <>
              {showManagementBtn === false ? (
                <></>
              ) : (
                <div>
                  <LaddaButton
                    width={
                      (currentUser?.cargo?.length ?? 0) > 10 ? "140px" : "110px"
                    }
                    fontSize={"13px"}
                    height={"32px"}
                    variant={!buttonMenu ? "contained" : "outlined"}
                    backgroundColor={!buttonMenu ? "#6A6AD8" : "#FFFF"}
                    color={!buttonMenu ? "#FFFF" : "#6A6AD8"}
                    borderColor={"#6A6AD8"}
                    margin={"7px 5px 0 5px"}
                    onClick={() => setButtonMenu(!buttonMenu)}
                  >
                    <p className="button_text">{currentUser?.cargo || ""}</p>
                  </LaddaButton>
                  <div
                    className={` animated-div ${buttonMenu ? "visible" : ""}`}
                  >
                    <div className="VContainer">
                      {frameTimeState == timeFrameState.ACTIVE && (
                        <Link
                          to="/self_appraisal"
                          onClick={() => {
                            dispatch(changeManagementHeaderBtnState(false));
                          }}
                        >
                          <LaddaButton
                            width={
                              (currentUser?.cargo?.length ?? 0) > 10
                                ? "140px"
                                : "110px"
                            }
                            fontSize={"12px"}
                            height={"32px"}
                            variant="contained"
                            margin={"0 5px"}
                          >
                            <p className="button_text">Autoevaluación</p>
                          </LaddaButton>
                        </Link>
                      )}
                      {frameTimeState == timeFrameState.INACTIVE && (
                        <LaddaButton
                          onClick={() => createDashboard()}
                          width={
                            (currentUser?.cargo?.length ?? 0) > 10
                              ? "140px"
                              : "110px"
                          }
                          fontSize={"12px"}
                          height={"32px"}
                          variant="contained"
                          margin={"2px 5px"}
                        >
                          <p className="button_text">Dashboard</p>
                        </LaddaButton>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <LaddaButton
                variant="outlined"
                backgroundColor={"#ffff"}
                color={"#6A6AD8"}
                borderColor={"#6A6AD8"}
                height={"32px"}
              >
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.lockReset}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Cambiar clave</p>
                </div>
              </LaddaButton>

              <LaddaButton variant="contained" height={"32px"}>
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.logoutWhite}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Salir</p>
                </div>
              </LaddaButton>
            </>
          ) : basicBtnsUrlArray.includes(locationUrl) === true ? (
            <>
              <LaddaButton
                variant="outlined"
                backgroundColor={"#ffff"}
                color={"#6A6AD8"}
                borderColor={"#6A6AD8"}
              >
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.lockReset}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Cambiar clave</p>
                </div>
              </LaddaButton>
              <LaddaButton variant="contained">
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.logoutWhite}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Salir</p>
                </div>
              </LaddaButton>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* ------------------------ MENU BTN ------------------------ */}
        <div className="button_container_mobile">
          <LaddaButton
            variant="outlined"
            backgroundColor={"#ffff"}
            color={"#6A6AD8"}
            onClick={() => handleMenuBtn()}
          >
            <MenuIcon />
          </LaddaButton>
        </div>
      </header>
      {/* ------------------------ MOBILE MENU ------------------------ */}

      <div style={{ display: showMenu === false ? "none" : "block" }}>
        <div className="button_menu">
          {/* <Link to="/general_management"> */}
          <LaddaButton
            variant={!buttonMenu ? "contained" : "outlined"}
            backgroundColor={!buttonMenu ? "#6A6AD8" : "#FFFF"}
            color={!buttonMenu ? "#FFFF" : "#6A6AD8"}
            margin={"7px 5px 0 5px"}
            onClick={() => setButtonMenu(!buttonMenu)}
          >
            <p className="button_text">Gerente</p>
          </LaddaButton>
          {/* </Link> */}
          <div
            className={`VContainer animated-div-mobile ${
              buttonMenu ? "visible" : ""
            }`}
            style={{ width: "100%" }}
          >
            <Link to="/self_appraisal">
              <LaddaButton width={"-moz-available"} variant="contained">
                <p className="button_text">Autoevaluación</p>
              </LaddaButton>
            </Link>
            <Link to="/metric_dashboard">
              <LaddaButton width={"-moz-available"} variant="contained">
                <p className="button_text">Dashboard</p>
              </LaddaButton>
            </Link>
          </div>
          <LaddaButton
            variant="outlined"
            backgroundColor={"#ffff"}
            color={"#6A6AD8"}
            borderColor={"#6A6AD8"}
          >
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.lockReset}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Cambiar clave</p>
            </div>
          </LaddaButton>
          <LaddaButton variant="contained">
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.logoutWhite}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Salir</p>
            </div>
          </LaddaButton>
        </div>
      </div>
    </>
  );
};

export default Header;
