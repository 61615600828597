import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import "./styles.css";
import { styled } from "@mui/material/styles";
import { icons } from "../../constants";

const BreadCrumbComp = ({personalized}) => {
  const location = useLocation();
  const path = location.pathname.split("/").filter((x) => x);

  const pathnames = path.splice(0, path.length);

  const BreadcrumbsStyled = styled(Breadcrumbs)(({ theme }) => ({
    margin: "35px 0",
  }));

  const breadcrumbNameMap = {
    "/dashboard": "Contexto de la Organización",
    "/self_appraisal/survey": personalized,
    "/superior_rol_dashboard": "Dashboard",
    "/dashboard/dofa/processDetails": "Detalles del Proceso",
    "/clients": "Dashboard",
    "/clients/topClients": "Listado de Clientes",
    "/clients/topProducts": "Listado de Productos",
    "/clients/topProducts/details": "Detalle del Producto",
    "/self_appraisal": "Evaluación",
    "/360_Report": "Informe 360°",
    "/superior_rol_dashboard/Pai_360": "PAI 360°",
    "/biomedicalManagementDashboard": "Dashboard",
    "/biomedicalManagementDashboard/indicator": personalized,
    "/biomedicalManagementDashboard/BioMedicalRequest": "Requerimientos",
    "/biomedicalManagementDashboard/BioMedicalRequest/createRequest":"Nuevo Requerimiento",
    "/riskAndOpportunityManagement": "Gestión de Riesgos y Oportunidades",
    "/dashboard/dofa": "DOFA",
    "/riskAndOpportunityManagement/dofa": "DOFA",
    "/riskAndOpportunityManagement/riskAdminGestion": "Riesgos - Gestión Administrativa",
    "/riskAndOpportunityManagement/riskAdminGestion/riskDetails": "Detalles del Riesgo",
    "/riskAndOpportunityManagement/opportunityAdminGestion": "Oportunidades - Gestión Administrativa",
    "/riskAndOpportunityManagement/opportunityAdminGestion/opportunityDetails": "Detalles del Oportunidad",
    "/riskAndOpportunityManagement/createRisk": "Nuevo Riesgo",
    "/riskAndOpportunityManagement/createOpportunity": "Nueva Oportunidad",
    
  };

  return (
    <BreadcrumbsStyled separator={<FaAngleRight />} aria-label="breadcrumb">
      <Link to="/">
        <img
          className="btn-icon"
          src={icons.homeGray}
          alt="icon"
          style={{ marginRight: "10px" }}
        />
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <p className="breadcrums_text" key={to}>
            {breadcrumbNameMap[to]}
          </p>
        ) : (
          <Link to={to}>
            <p className="breadcrums_off_text">{breadcrumbNameMap[to]}</p>
          </Link>
        );
      })}
    </BreadcrumbsStyled>
  );
};

export default BreadCrumbComp;
