import React, { useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { CustomTabPanel } from "../../../components/tabComponent";
import RiskDetailModal from "./riskDetailModal";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";

const RiskDetailsTab = ({ data, getRisk, isEditionAvailable }) => {
  const [riskDetailModal, setRiskDetailModal] = useState(false);

  const handleClose = () => {
    setRiskDetailModal(false);
  };

  const openModal = () => {
    setRiskDetailModal(true);
  };

  return (
    <div>
      <CustomTabPanel value="0">
        <h2 className="gm-title">Descripción:</h2>
        <p className="content-text">{data?.description}</p>
        <h2 className="gm-title">Causa Básica:</h2>
        <p className="content-text">{formatTextWithLineBreaks(data?.causes)}</p>
        <h2 className="gm-title">Consecuencia/Efecto:</h2>
        <p className="content-text">
          {formatTextWithLineBreaks(data?.consequences)}
        </p>
        <h2 className="gm-title">Plan de Contingencia:</h2>
        <p className="content-text">
          {formatTextWithLineBreaks(data?.contingencyPlan)}
        </p>
        <div className="j-center">
          {isEditionAvailable && (
            <LaddaButton
              variant="contained"
              onClick={() => {
                openModal();
              }}
            >
              <div className="HContainer align-rigth">
                <img
                  className="btn-icon"
                  src={icons.editWhite}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Editar</p>
              </div>
            </LaddaButton>
          )}
        </div>
      </CustomTabPanel>
      <RiskDetailModal
        open={riskDetailModal}
        handleClose={handleClose}
        data={data}
        getRisk={getRisk}
        isEditionAvailable={isEditionAvailable}
      />
    </div>
  );
};

export default RiskDetailsTab;
