const icons = {
  addPlus: "/icons/add.png",
  mindMap: "/icons/sitemap-solid.png",
  group: "/icons/people-group-solid.png",
  prooveedores: "/icons/proovedores 1.png",
  tieMan: "/icons/user-tie-solid.png",
  checkList: "/icons/Vector.png",
  graphBar: "/icons/chart-simple-solid.png",
  handHeart: "/icons/hand-holding-heart-solid.png",

  lockReset: "/icons/lock_reset.png",
  logoutBlue: "/icons/logout_blue.png",
  logoutWhite: "/icons/logout_white.png",
  home: "/icons/home.png",
  eye: "/icons/visibilityeye.svg",
  prescriptionBottle: "/icons/prescription-bottle.svg",
  tieManWhite: "/icons/user-tie-solid-w.svg",
  editWhite: "/icons/edit_white.svg",
  editBlack: "/icons/edit.png",
  editBlue: "/icons/edit_blue.svg",
  editYellow: "/icons/edit_yellow.png",
  blackBell: "/icons/black_bell.png",
  lineChard: "/icons/lineChard.svg",
  arrow_drop_up: "/icons/arrow_drop_up.png",
  arrow_drop_down: "/icons/arrow_drop_down.png",
  comments_icon: "/icons/comments_icon.png",

  homeGray: "/icons/home_gray.png",
  contract: "/icons/contrato.svg",
  verificationList: "/icons/lista-de-verificacion.svg",
  pcMonitor: "/icons/monitor-de-computadora.svg",
  rightBlack: "/icons/chevron_right_black.svg",
  link: "/icons/unirr.svg",
  exclamationboard: "/icons/exclamationboard.svg",
  infoExclamation: "/icons/infoExclamation.png",
  mentalmap: "/icons/save2.svg",
  archivo: "/icons/archivo.svg",
  evidencia: "/icons/evidencia.svg",
  downloadIcon: "/icons/download.svg",
};

export default icons;
