import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import LaddaButton from "../../components/buttons/laddaButton";
import SimpleTable from "../../components/tables/simpleTable";
import {
  tableASelfAppraisalBurnData,
  tableBSelfAppraisalBurnData,
} from "../../utils/burnData";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { getCookie } from "../../utils/getCookie";
import { useDispatch, useSelector } from "react-redux";
import { setEvaluationData } from "../../redux/slices/evaluationSlice";
import { timeFrameState } from "../../constants/enums";
import {
  callApiAsync,
  getCallAutoEvaluation,
  getCallColabSurvey,
  getCallManagerSurvey,
  getCallPairSurvey,
  getCallTimeFrameState,
} from "../../services/apiService";

const SelfAppraisal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reloadPage = useSelector((state) => state.reload.reload);

  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;
  const [seftTable, setSeftTable] = useState([]);
  const [pairTable, setPairTable] = useState([]);
  const [collabTable, setCollabTable] = useState([]);
  const [managerTable, setManagerTable] = useState([]);
  const [frameTimeState, setFrameTimeState] = useState(0);

  const getAutoEvaluation = async () => {
    callApiAsync(
      () => getCallAutoEvaluation(userId),
      (data) => {
        setSeftTable(data);
      }
    );
  };

  const getPairSurvey = async () => {
    callApiAsync(
      () => getCallPairSurvey(userSesion.id_usuario),
      (data) => {
        setPairTable(data);
      }
    );
  };

  const getColabSurvey = async () => {
    callApiAsync(
      () =>
        getCallColabSurvey(userSesion.id_usuario, new Date().getUTCFullYear()),
      (data) => {
        setCollabTable(data);
      }
    );
  };

  const getManagerSurvey = async () => {
    callApiAsync(
      () =>
        getCallManagerSurvey(
          userSesion.id_usuario,
          new Date().getUTCFullYear()
        ),
      (data) => {
        setManagerTable(data);
      }
    );
  };

  const getFrameTimeState = async () => {
    callApiAsync(
      () => getCallTimeFrameState(),
      (data) => {
        setFrameTimeState(data.state);
      }
    );
  };

  const loadTables = () => {
    getAutoEvaluation();
    getPairSurvey();
    getColabSurvey();
    getManagerSurvey();
    getFrameTimeState();
  };

  useEffect(() => {
    loadTables();
  }, []);
  useEffect(() => {
    loadTables();
  }, [reloadPage]);

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">AUTOEVALUACIÓN</h1>
      </div>

      <div className="table-container">
        <h1 className="progress-label-title">Autoevaluación</h1>
        <SimpleTable
          data={seftTable}
          columns={tableASelfAppraisalBurnData.columns}
          scrollHeight={"300px"}
          text={"Realizar Autoevaluación"}
          buttons={[{ type: "button" }]}
          onClickftn={(data) => {
            dispatch(
              setEvaluationData({
                totalAnswers: data.totalAnswers,
                surveyId: data.surveyId,
              })
            );
            navigate(`/self_appraisal/survey`, {
              state: {
                surveyId: data.surveyId,
                surveyUserId: data.id,
                type: "self_appraisal",
                userId: data.userId,
                totalAnswers: data.totalAnswers,
              },
            });
          }}
          hideBtn={frameTimeState == timeFrameState.ACTIVE}
        />
      </div>

      <div className="table-container">
        <h1 className="progress-label-title">Evaluación de pares</h1>
        <SimpleTable
          data={pairTable}
          columns={tableBSelfAppraisalBurnData.columns}
          scrollHeight={"400px"}
          text={"Realizar Evaluación"}
          buttons={[{ type: "button" }]}
          onClickftn={(data) => {
            dispatch(
              setEvaluationData({
                totalAnswers: data.totalAnswers,
                surveyId: data.surveyId,
              })
            );
            navigate(`/self_appraisal/survey`, {
              state: {
                surveyId: data.surveyId,
                surveyUserId: data.id,
                type: "peer_evaluation",
                userId: data.userId,
                totalAnswers: data.totalAnswers,
              },
            });
          }}
          hideBtn={frameTimeState == timeFrameState.ACTIVE}
        />
      </div>

      <div className="table-container">
        <h1 className="progress-label-title">Evaluación de colaboradores</h1>
        <SimpleTable
          data={collabTable}
          columns={tableBSelfAppraisalBurnData.columns}
          scrollHeight={"400px"}
          text={"Realizar Evaluación"}
          buttons={[{ type: "button" }]}
          onClickftn={(data) => {
            dispatch(
              setEvaluationData({
                totalAnswers: data.totalAnswers,
                surveyId: data.surveyId,
              })
            );
            navigate(`/self_appraisal/survey`, {
              state: {
                surveyId: data.surveyId,
                surveyUserId: data.id,
                type: "collaborators_evaluation",
                userId: data.userId,
                totalAnswers: data.totalAnswers,
              },
            });
          }}
          hideBtn={frameTimeState == timeFrameState.ACTIVE}
        />
      </div>

      <div className="table-container">
        <h1 className="progress-label-title">Evaluación de superiores</h1>
        <SimpleTable
          data={managerTable}
          columns={tableBSelfAppraisalBurnData.columns}
          scrollHeight={"400px"}
          text={"Realizar Evaluación"}
          buttons={[{ type: "button" }]}
          onClickftn={(data) => {
            dispatch(
              setEvaluationData({
                totalAnswers: data.totalAnswers,
                surveyId: data.surveyId,
              })
            );
            navigate(`/self_appraisal/survey`, {
              state: {
                surveyId: data.surveyId,
                surveyUserId: data.id,
                type: "superiors_evaluation",
                userId: data.userId,
                totalAnswers: data.totalAnswers,
              },
            });
          }}
          hideBtn={frameTimeState == timeFrameState.ACTIVE}
        />
      </div>
    </div>
  );
};

export default SelfAppraisal;
