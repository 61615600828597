import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import ManagmentBanner from "../../components/managmentBanner";
import SimpleSelect from "../../components/selects/simpleSelect";
import DofaComponent from "../../components/dofaComponent";
import { Grid } from "@mui/material";
import ColorIndicator from "./colorIndicator";
import { months, stateDofa, years } from "../../constants/selectData";
import { useForm } from "react-hook-form";
import "./styles.css";
import { getCurrentMonthYear, getDateObj } from "../../utils/dateUtils";
import { getCookie } from "../../utils/getCookie";
import { useLocation } from "react-router-dom";
import {
  callApiAsync,
  getCallDofaByUser,
  getCallJsonSettingVersion,
} from "../../services/apiService";
import { canEditByRol } from "./authorizeFunctions.js";
import { useSelector } from "react-redux";

const Dofa = () => {
  const userData = useSelector((state) => state.userData.userData);

  const { control, getValues, setValue } = useForm();
  const [dofa, setDofa] = useState([]);
  const [isAvailableToAdd, setIsAvailableToAdd] = useState(false);
  const [filter, setFilter] = useState(null);
  const userSesion = getCookie("sesion");

  const location = useLocation();
  const { rowData } = location.state || {};

  console.log("row data", rowData);
  console.log("row data", rowData.ProcessId);

  const processId = 1;
  const month = location.state?.month;
  const year = location.state?.year;

  const handleChange = () => {
    isEditable();
  };
  const getVersion = async () => {
    callApiAsync(getCallJsonSettingVersion);
  };

  const getDofaByUser = async (year, month, state) => {
    callApiAsync(
      () =>
        getCallDofaByUser(
          userSesion.id_usuario,
          month,
          year,
          rowData.ProcessId,
          state
        ),
      (data) => {
        setDofa(data);
      }
    );
  };

  const isEditable = () => {
    const isEditableByRol = canEditByRol(userData.roleList, rowData.ProcessId);
    const formData = getValues();
    var isEditableByDate = getCurrentMonthYear(formData.year, formData.month);
    if (isEditableByDate) {
      setIsAvailableToAdd(true);
      if (!isEditableByRol) {
        setIsAvailableToAdd(false);
      }
    }else{
      setIsAvailableToAdd(false);
    }
    if (formData.state != 1) {
      setIsAvailableToAdd(false);
    }
    setFilter(formData);
    getDofaByUser(formData.year, formData.month, formData.state);
  };

  useEffect(() => {
    setValue("month", rowData ? rowData.month : getDateObj().month);
    setValue("year", rowData ? rowData.year : getDateObj().year);
    setValue("state", 0);
    getDofaByUser(rowData.year, rowData.month, 1);
    setFilter({ year: rowData.year, month: rowData.month, state: 1 });
    getVersion();
    setValue("state", 1);
    isEditable();
  }, []);

  return (
    <div className="App">
      <BreadCrumbComp />
      <ManagmentBanner />
      <div className="searchTitle">
        <h1 className="page-title">{rowData.ProcessName} - DOFA</h1>
        <div className="HContainer">
          <ColorIndicator />
          <div className="HContainer" style={{ margin: "0 20px 0 0" }}>
            <form style={{ display: "flex" }}>
              <SimpleSelect
                name="state"
                control={control}
                label="Estado"
                options={stateDofa}
                onChange={handleChange}
              />
              <div style={{ margin: "0 10px" }}>
                <SimpleSelect
                  name="month"
                  control={control}
                  label="Mes"
                  options={months}
                  onChange={handleChange}
                />
              </div>
              <SimpleSelect
                name="year"
                control={control}
                label="Año"
                options={years}
                onChange={handleChange}
              />
            </form>
          </div>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 1)}
            title="Debilidades"
            color={"#FED7AA"}
            year={getDateObj().year}
            month={getDateObj().month}
            processId={rowData.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 2)}
            title="Oportunidades"
            color={"#BAE6FD"}
            year={getDateObj().year}
            month={getDateObj().month}
            processId={rowData.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 3)}
            title="Fortalezas"
            color={"#BBF7D0"}
            year={getDateObj().year}
            month={getDateObj().month}
            processId={rowData.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <DofaComponent
            data={dofa.filter((item) => item.Type === 4)}
            title="Amenazas"
            color={"#FECDD3"}
            year={getDateObj().year}
            month={getDateObj().month}
            processId={rowData.ProcessId}
            getDofaByUser={getDofaByUser}
            filterdata={filter}
            isAvailableToAdd={isAvailableToAdd}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dofa;
