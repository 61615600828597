import React, { useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { CustomTabPanel } from "../../../components/tabComponent";
import GoalPaiModal from "./goalPaiModal";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";

const GoalPaiTab = ({ data, getPaiData }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };


  return (
    <div>
      <CustomTabPanel value="0">
        <h2 className="gm-title">¿Qué le gustaría lograr?</h2>
        <div className="HContainer">
          <p className="content-text min-w-700">{formatTextWithLineBreaks(data?.goals)}</p>
          <div className="mv-20">
            <LaddaButton
              variant="contained"
              borderColor={"#6A6AD8"}
              backgroundColor={"#FFFF"}
              onClick={() => handleOpenModal()}
              width={"50px"}
            >
              <img className="btn-icon-20" src={icons.editBlue} alt="icon" />
            </LaddaButton>
          </div>
        </div>
        <h2 className="gm-title">Seguimiento por:</h2>
        <p className="content-text">{data?.followUpBy}</p>
      </CustomTabPanel>
      <GoalPaiModal
        open={openModal}
        handleClose={handleClose}
        data={data}
        getPaiData={getPaiData}
      />
    </div>
  );
};

export default GoalPaiTab;
