import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";

const FormTextField = ({
  name,
  control,
  label,
  defaultValue,
  formLabel,
  isTextArea,
  rows,
  error,
  maxLength,
  disabled,
  styledlabel,
  ...othersProps
}) => {
  const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    height: isTextArea ? "auto" : "40px",
    margin: isTextArea ? (rows ? `0 10px` : "0 10px") : "0 10px",
    borderRadius: "6px",
    "& .MuiInputBase-input": {
      padding: "4.5px",
    },
    "& .MuiInputBase-root": {
      padding: "5px 7px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "10.5px",
      margin: "3px 0 0 0",
    },
  }));

  const StyledInputLabel = styled(InputLabel)(() => ({
    top: "-10px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "-10px 0px -15px 15px",
  }));

  return (
    <Box>
      {label ? <p className="text-form-label">{label}</p> : <></>}
      {styledlabel ? (
        <StyledInputLabel shrink={true} id={`${name}-label`}>
          {styledlabel}
        </StyledInputLabel>
      ) : (
        <></>
      )}
      <FormControl fullWidth error={Boolean(error)} className="formController">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <StyledTextField
              {...field}
              inputProps={{
                maxLength: maxLength,
              }}
              helperText={
                maxLength &&
                `Este campo solo permite hasta ${maxLength} caracteres.`
              }
              label={formLabel}
              variant="outlined"
              multiline={isTextArea}
              rows={isTextArea ? rows || 4 : 1}
              onChange={(event) => field.onChange(event)}
              disabled={disabled}
              {...othersProps}
            />
          )}
        />
        {error && (
          <p className="error-message formTexfield-error">{error.message}</p>
        )}
      </FormControl>
    </Box>
  );
};

export default FormTextField;
