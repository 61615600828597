import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LaddaButton from "../../components/buttons/laddaButton";
import { styled } from "@mui/material/styles";
import "./styles.css";
import SimpleTable from "../../components/tables/simpleTable";
import BreadCrumbComp from "../../components/breadCrumb";
import { icons } from "../../constants";
import { Checkbox } from "@mui/material";

const panel = [
  { name: "Detalles del Proceso", id: "0" },
  { name: "Detalles del Riesgo/Oportunidad", id: "1" },
  { name: "Estatus del Riesgo/Oportunidad", id: "2" },
  { name: "Registro por Mes", id: "3" },
];

const dataTable = {
  columns: [
    { field: "month", header: "Mes", columnType: "titles" },
    { field: "actions", header: "Acciones" },
    { field: "state", header: "Estado" },
    { field: "priority", header: "Prioridad", columnType: "pills" },
    {
      field: "updates",
      header: "Actualizaciones",
      columnType: "titles",
    },
    { field: "actionButtons", header: "", columnType: "actionButtons" },
  ],
  data: [
    {
      month: "Enero",
      actions:
        "Se mantienen los criterios de selección y las contrataciones realizadas bajo...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Febrero",
      actions:
        "Se mantienen los criterios de selección y las contrataciones realizadas bajo...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Marzo",
      actions:
        "Se mantienen los criterios de selección y las contrataciones realizadas bajo...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Abril",
      actions:
        "1. Revision del P-GDA-001 Procedimiento selección y evaluacion...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Mayo",
      actions:
        "Se mantienen los criterios de selección y las contrataciones realizadas bajo...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Junio",
      actions:
        "Se mantienen los criterios de selección y las contrataciones realizadas bajo...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
    {
      month: "Julio",
      actions:
        "La organizacion adquiere esta herramientas para los diferentes...",
      state: "Latente",
      priority: "Medio",
      updates:
        "\\192.168.0.200seranestSIG 2023GESTION DE CALIDAD ÁREASADMINISTRATIVA...",
    },
  ],
};

//----------------------------------- DATE -----------------------------------

const today = new Date();
const day = String(today.getDate()).padStart(2, "0");
const month = String(today.getMonth() + 1).padStart(2, "0");
const year = today.getFullYear();

const date = `${day}/${month}/${year}`;

const ProcessDetails = () => {
  const [value, setValue] = useState("1");
  const [checked, setChecked] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabContextStyled = styled(TabContext)(({ theme }) => ({
    "& .MuiTab-root": {
      color: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }));

  const CustomTab = styled(Tab)(({ theme }) => ({
    minWidth: "auto",
    padding: "35px 25px",
    textTransform: "initial",
    fontSize: "16px,",
    fontWeight: "900",
    fontFamily: "Lato",
    color: "#6A6AD8",
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#6A6AD8",
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "50px",
    border: `1px solid ${theme.palette.divider}`,
    borderTop: `0`,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    fontFamily: "Lato",
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    // borderTop: `1px solid ${theme.palette.divider}`,
  }));

  const CustomTabList = styled(TabList)(({ theme }) => ({
    backgroundColor: "#ffff",
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  }));

  const getChildren = (id) => {
    switch (id) {
      case "0":
        return (
          <CustomTabPanel value="0">
            <h2 className="gm-title">Descripción:</h2>
            <p className="content-text">
              Inadecuada selección y contratación de proveedores de servicios.
            </p>
            <h2 className="gm-title">Causa Básica:</h2>
            <ul>
              <li className="li-item">
                1. No seguir los lineamientos establecidos en el procedimiento.{" "}
              </li>

              <li className="li-item">
                2. No analizar y verificar adecuadamente las competencias de los
                proveedores de servicio para la prestación del mismo.
              </li>
            </ul>
            <h2 className="gm-title">Consecuencia/Efecto:</h2>
            <ul>
              <li className="li-item">1. Sanciones legales y económicas.</li>
              <li className="li-item">2. Productos de baja calidad.</li>
              <li className="li-item">
                3. Afectación al desarrollo interno de la organización.
              </li>
            </ul>
            <div className="j-center">
              <LaddaButton variant="contained">
                <div className="HContainer align-rigth">
                  <img
                    className="btn-icon"
                    src={icons.editWhite}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Editar</p>
                </div>
              </LaddaButton>
            </div>
          </CustomTabPanel>
        );
      case "1":
        return (
          <CustomTabPanel value="1">
            <div className="header-titles">
              <div className="header-titles-item">
                <h2 className="gm-title" style={{ marginRight: "15px" }}>
                  Fecha de Identificación:
                </h2>
                <p className="content-text">01/2020</p>
              </div>
              <div className="header-titles-item">
                <h2 className="gm-title" style={{ marginRight: "15px" }}>
                  Amenaza/Oportunidad:
                </h2>
                <p className="content-text">Riesgo</p>
              </div>
              <div className="header-titles-item">
                <h2 className="gm-title" style={{ marginRight: "15px" }}>
                  Valoración Global:
                </h2>
                <p className="content-text">Alto</p>
              </div>
            </div>
            <h2 className="gm-title">Acción de Tratamiento:</h2>
            <ul>
              <li className="li-item">
                1. Implementar los criterios de selección de proveedores de
                servicio.{" "}
              </li>
              <li className="li-item">
                2. Regirse al procedimiento establecido.
              </li>
              <li className="li-item">
                3. Verificar los documentos legales y requisitos antes de la
                contratación.
              </li>
              <li className="li-item">
                4. Solicitar al menos tres cotizaciones cuando se requieran
                proveedores o servicio nuevos.
              </li>
            </ul>
            <div className="header-titles-item">
              <h2 className="gm-title">Fecha de Inicio de Actividades:</h2>
              <p className="content-text">01/2020</p>
            </div>
            <h2 className="gm-title">Responsable:</h2>
            <ul>
              <li className="li-item">
                1. Asistente Administrativo y de gestión humana.{" "}
              </li>
              <li className="li-item">
                2. Gestor servicio al cliente y gestión comercial.
              </li>
            </ul>

            <div className="HContainer align-center">
              <div style={{ margin: 0 }} className="tab-pill">
                Estado de la Acción:
              </div>
              <p style={{ marginLeft: "20px" }}>Abierta</p>
            </div>

            <div className="j-center">
              <LaddaButton variant="contained">
                <div className="HContainer align-rigth">
                  <img
                    className="btn-icon"
                    src={icons.editWhite}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Editar</p>
                </div>
              </LaddaButton>
            </div>
          </CustomTabPanel>
        );
      case "2":
        return (
          <CustomTabPanel value="2">
            <div className="HContainer align-center">
              <div className="tab-pill">
                <p style={{ margin: 0 }}>Estado del Riesgo:</p>
              </div>
              <p style={{ marginLeft: "20px" }}>Latente</p>
            </div>

            <h2 className="gm-title">Estatus del Riesgo y/o Oportunidad:</h2>
            <p className="content-text">
              Se implementa un plan de acción con el fin de actualizar el
              proceso de Gestión Administrativa debido a que los meses pasados
              no se contaba con la persona idónea para suplir las necesidades
              del proceso.
            </p>

            <div className="HContainer align-center">
              <h2 className="gm-title">Riesgo Residual:</h2>
              <div className="tab-pill" style={{ marginLeft: "20px" }}>
                <p style={{ margin: 0 }}>Medio</p>
              </div>
            </div>
            <h2 className="gm-title">Evidencia del Riesgo Residual:</h2>

            <p className="content-text">Ver acción correctiva.</p>
            <h2 className="gm-title">Eficaz:</h2>
            <div className="HContainer">
              <Checkbox
                checked={checked === "si" ? true : false}
                onChange={() => setChecked("si")}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p>Sí</p>
              <Checkbox
                checked={checked === "no" ? true : false}
                onChange={() => setChecked("no")}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p>No</p>
            </div>
            <div className="j-center">
              <LaddaButton variant="contained">
                <div className="HContainer align-rigth">
                  <img
                    className="btn-icon"
                    src={icons.editWhite}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Editar</p>
                </div>
              </LaddaButton>
            </div>
          </CustomTabPanel>
        );
      case "3":
        return (
          <CustomTabPanel value="3">
            <div className="j-end">
              <LaddaButton variant="contained">
                <div className="HContainer align-rigth">
                  <img
                    className="btn-icon"
                    src={icons.addPlus}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Agregar</p>
                </div>
              </LaddaButton>
            </div>
            <SimpleTable
              data={dataTable.data}
              columns={dataTable.columns}
              buttons={["edit"]}
              //   onClickftn={() => console.log("click")}
            />
          </CustomTabPanel>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <div className="gm-container">
        <BreadCrumbComp />
        <div className="gm-header">
          <div className="HContainer">
            <h1
              className="gm-header-title-grey"
              style={{ marginRight: "15px" }}
            >
              N°: R45:
            </h1>
            <h1 className="gm-header-title">Gestión Administrativa</h1>
          </div>
          <div className="gm-header">
            <p className="lastC-text">Última Actualización:</p>
            <p className="li-item">{date}</p>
          </div>
        </div>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContextStyled value={value}>
            <CustomBox sx={{ borderBottom: 1, borderColor: "divider" }}>
              <CustomTabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {panel.map((item) => {
                  return <CustomTab label={item.name} value={item.id} />;
                })}
              </CustomTabList>
            </CustomBox>
            {panel.map((item) => {
              return getChildren(item.id);
            })}
          </TabContextStyled>
        </Box>
      </div>
    </div>
  );
};

export default ProcessDetails;
