import React, { useState, useEffect, useCallback } from "react";
import ManagmentBanner from "../../components/managmentBanner";
import SimpleSelect from "../../components/selects/simpleSelect";
import FormTextField from "../../components/textField/formTextField";
import LaddaButton from "../../components/buttons/laddaButton";
import ModalComponent from "../../components/modals";
import SimpleTable from "../../components/tables/simpleTable";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { icons } from "../../constants";
import { Grid } from "@mui/material";
import "./styles.css";
import { getCookie } from "../../utils/getCookie";
import { useNavigate } from "react-router-dom";
import BreadCrumbComp from "../../components/breadCrumb";
import { useSelector } from "react-redux";
import {
  callApiAsync,
  getCallProcess,
  getCallRiskType,
  postCreateOpportunity,
} from "../../services/apiService";
import { filterProcessByRole } from "./authorizeFxs";

// Esquema de validación
const validationSchema = yup.object().shape({
  opportunityType: yup.string().required("Este campo es obligatorio"),
  opportunityTitle: yup.string().required("Este campo es obligatorio"),
  description: yup
    .string()
    .required("Este campo es obligatorio")
    .max(500, "La descripción no puede exceder los 500 caracteres"),
  impactProcess: yup.string().required("Este campo es obligatorio"),
  benefits: yup
    .string()
    .required("Este campo es obligatorio")
    .max(500, "Las causas no pueden exceder los 500 caracteres"),
  impact: yup.string().required("Este campo es obligatorio"),
});

const columnsHelp = [
  { field: "name", header: "Tipo de riesgo" },
  {
    field: "description",
    header: "Definición del tipo de riesgo",
    columnType: "fullText",
  },
];

const dataHelp = [
  {
    name: "Estratégico",
    description:
      "Evento potencial que puede menoscabar la implementacion de la estrategia de la organización de negocios o el logro de las metas estrategicas incluye el riesgo legal y reputacional, asociados tambien a factores externos",
  },
  {
    name: "Operativo",
    description:
      "Posibilidad de incurrir en perdidas por deficiencia fallas e inadecuaciones en recurso humano, procesos, la tecnologia, la infraestructura",
  },
  {
    name: "Financiero",
    description:
      "Es la incertidumbre producida en el rendimiento de una inversión, debida a los cambios producidos en el sector en el que se opera, a la imposibilidad de devolución del capital por una de las partes y a la inestabilidad de los mercados financieros.",
  },
  {
    name: "Tecnológico",
    description:
      "Son los daños o pérdidas potenciales que pueden presentarse debido a los eventos mayores generados por el uso y acceso a la tecnología, originados en sucesos antrópicos, naturales, socio-naturales y propios de la operación.",
  },
  {
    name: "Económico",
    description:
      "Es la incertidumbre producida en el rendimiento de la inversión debida a los cambios producidos en la situación económica del sector en el que opera la empresa",
  },
  {
    name: "Laboral",
    description:
      "Accidentes y enfermedades que puedan ocurrir con ocasión o como consecuencia del trabajo que desarrollan las personas.",
  },
  {
    name: "Legal",
    description:
      " El riesgo legal y contractual (Legal/Contract Risk) se refiere al riesgo que corren los administradores de una compañía por no manejar en forma  apropiada los riesgos a nivel ambiental, social y de gobernabilidad, y que pueden conducir a demandas legales u otras soluciones judiciales. ",
  },
];

const CreateOpportunity = () => {
  const navigate = useNavigate();
  const userSesion = getCookie("sesion");
  const userData = useSelector((state) => state.userData.userData);

  const userId = userSesion.id_usuario;
  const [openModal, setOpenModal] = useState(false);
  const [opportunityType, setOpportunityType] = useState([]);
  const [process, setProcess] = useState([]);
  const [riskType, setRiskType] = useState([]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const createOpportunity = async (data) => {
    const objRequest = {
      userId: userSesion.id_usuario,
      processId: data.impactProcess,
      description: data.description,
      title: data.title,
      riskTypeId: data.riskTypeId,
      benefits: data.benefits,
      impact: data.impact,
    };

    callApiAsync(
      () => postCreateOpportunity(objRequest),
      (data) => {
        const opportunityId = data.insertId;
        navigate(
          "/riskAndOpportunityManagement/opportunityAdminGestion/opportunityDetails",
          {
            state: {
              rowData: {
                id: opportunityId,
              },
            },
          }
        );
      }
    );
  };

  const onSubmit = async (formData) => {
    const dataToSend = {
      usuariosId: userId,
      riskTypeId: formData.opportunityType,
      title: formData.opportunityTitle,
      description: formData.description,
      impactProcess: formData.impactProcess,
      benefits: formData.benefits,
      impact: formData.impact,
    };
    await createOpportunity(dataToSend);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const getOpportunityType = async () => {
    callApiAsync(getCallRiskType, (data) => {
      setOpportunityType(data);
    });
  };

  const getProcess = async () => {
    callApiAsync(getCallProcess, (data) => {
      var dataFiltered = filterProcessByRole(userData.roleList, data);
      setProcess(dataFiltered);
    });
  };

  useEffect(() => {
    getOpportunityType();
    getProcess();
  }, []);

  return (
    <div>
      <BreadCrumbComp />
      <ManagmentBanner />
      <div className="searchTitle riskAndOpportunityManagement-title">
        <h1>CREAR NUEVA OPORTUNIDAD</h1>
      </div>

      <div className="create-form-container">
        <p className="createRist-title"> Oportunidad</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="HContainer align-center">
                <p className="create-label">Tipo de Oportunidad:</p>
                <div
                  className="pointer align-center j-center"
                  onClick={() => setOpenModal(true)}
                >
                  <img
                    className="btn-add-icon"
                    src={icons.infoExclamation}
                    alt="icon"
                    style={{ margin: "0 0 7px 10px", width: "30px" }}
                  />
                </div>
              </div>
              <SimpleSelect
                name="opportunityType"
                control={control}
                options={opportunityType}
                error={errors.opportunityType}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Título de la Oportunidad:</p>
              <FormTextField
                name="opportunityTitle"
                control={control}
                error={errors.opportunityTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Descripción:</p>
              <FormTextField
                name="description"
                control={control}
                isTextArea={true}
                error={errors.description}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Proceso de Impacto:</p>
              <SimpleSelect
                name="impactProcess"
                control={control}
                options={process}
                error={errors.impactProcess}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Beneficios:</p>
              <FormTextField
                name="benefits"
                control={control}
                isTextArea={true}
                error={errors.benefits}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Impacto:</p>
              <FormTextField
                name="impact"
                control={control}
                isTextArea={true}
                error={errors.impact}
                maxLength={500}
              />
            </Grid>
          </Grid>
          <div className="j-center white-space">
            <LaddaButton
              width={"160px"}
              fontSize={"12px"}
              height={"40px"}
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              <p className="button_text">Crear Oportunidad</p>
            </LaddaButton>
          </div>
        </form>
      </div>

      <ModalComponent open={openModal} handleClose={handleClose} title={""}>
        <SimpleTable data={opportunityType} columns={columnsHelp} />
      </ModalComponent>
    </div>
  );
};

export default CreateOpportunity;
