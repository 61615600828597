import React from "react";
import Cookies from "js-cookie";

const Login = () => {
  const urlParams = new URLSearchParams(window.location.search);
  
  const id_usuario = urlParams.get("id_usuario");

  if (!id_usuario) {
    window.location.href = "https://gestion.seranestpharma.com/index.php";
    return;
  }

  const inicio = urlParams.get("inicio");
  const fin = urlParams.get("fin");
  const totales = urlParams.get("totales");
  const sesion = {
    id_usuario,
    inicio,
    fin,
  };
  Cookies.set("sesion", JSON.stringify(sesion)); // Convertimos el objeto a una cadena JSON

  if (totales > 0) {
    window.location.href = "https://gestion.seranestpharma.com/tareas.php?a=mt";
  } else {
    window.location.href = "https://gestion.seranestpharma.com/index.php?a=home&mapa=1";
  }
  return <p>login</p>;
};

export default Login;
