import { useForm } from "react-hook-form";
import LaddaButton from "../../../components/buttons/laddaButton";
import ModalComponent from "../../../components/modals";
import {
  callApiAsync,
  postSaveEmployeeComment,
} from "../../../services/apiService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import { useEffect } from "react";

const ModalEditComment = ({
  open,
  handleClose,
  currentCommentRow,
  getUsersInMonitoringFx,
}) => {
  const onSubmit = async (formData) => {
    const dataToSend = {
      id: currentCommentRow.commentId ? currentCommentRow.commentId : 0,
      userId: currentCommentRow.id,
      comment: formData.comment,
    };
    callApiAsync(
      () => postSaveEmployeeComment(dataToSend),
      () => {
        getUsersInMonitoringFx();
        handleCloseFx();
      }
    );
  };

  const validationSchema = yup.object().shape({
    comment: yup.string().required("Este campo es obligatorio"),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const loadData = () => {
    currentCommentRow
      ? setValue("comment", currentCommentRow.comment)
      : setValue("comment", "");
  };

  const handleCloseFx = () => {
    clearData();
    handleClose();
  };

  const clearData = () => {
    setValue("comment", "");
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleCloseFx}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar</p>
              </div>
            </LaddaButton>
            <LaddaButton variant="contained" onClick={handleClose}>
              <div className="HContainer align-center">
                <p className="button_text">Cancelar</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <h2>{currentCommentRow.employee}</h2>
        <form className="VContainer form-modal">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <p className="create-label">Comentarios:</p>
              <FormTextField
                name="comment"
                control={control}
                isTextArea={true}
                error={errors.benefits}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default ModalEditComment;
