import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { YesNoOptions, riskStatus } from "../../../constants/selectData";
import FormRadioButton from "../../../components/formRadioButton";
import SimpleSelect from "../../../components/selects/simpleSelect";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  postUpdateStatusRisk,
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  actionState: yup.string().required("Este campo es obligatorio"),
  statusRisk: yup.string().required("Este campo es obligatorio"),
  evidenceResidualRisk: yup.string().required("Este campo es obligatorio"),
  effective: yup.boolean().required("Este campo es obligatorio"),
});

const RiskStatusModal = ({ open, handleClose, data, getRisk }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    reset({
      actionState: data.state || "",
      statusRisk: data.statusRisk || "",
      evidenceResidualRisk: data.evidenceResidualRisk || "",
      effective:
        data.effective == null ? null : data.effective == 1 ? true : false,
    });
    clearData();
    handleClose();
  };

  const loadData = () => {
    setValue("actionState", data?.state);
    setValue("statusRisk", data?.statusRisk);
    setValue("evidenceResidualRisk", data?.evidenceResidualRisk);
    setValue(
      "effective",
      data.effective == null ? null : data.effective == 1 ? true : false
    );
  };

  const clearData = () => {
    setValue("actionState", "");
    setValue("statusRisk", "");
    setValue("evidenceResidualRisk", "");
    setValue("effective", "");
  };

  const updateRiskstatus = async (formData) => {
    const objRequest = {
      id: data.riskMonthId,
      state: formData.actionState,
      statusRisk: formData.statusRisk,
      evidenceResidualRisk: formData.evidenceResidualRisk,
      effective: formData.effective,
    };

    callApiAsync(
      () => postUpdateStatusRisk(objRequest),
      () => {
        getRisk();
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset({
        actionState: data.state || "",
        statusRisk: data.statusRisk || "",
        evidenceResidualRisk: data.evidenceResidualRisk || "",
        effective:
          data.effective == null ? null : data.effective == 1 ? true : false,
      });
    }
  }, [data, reset]);

  const onSubmitRiskStatusDetail = async (formData) => {
    await updateRiskstatus(formData);
    handleCloseModal();
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton
              variant="contained"
              onClick={handleSubmit(onSubmitRiskStatusDetail)}
            >
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer form-modal m-20">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="detail-label">Estado del Riesgo:</p>
              <SimpleSelect
                name="actionState"
                control={control}
                hideLabel={true}
                options={riskStatus}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="detail-label">Estatus del Riesgo:</p>
              <FormTextField
                name="statusRisk"
                control={control}
                error={errors.statusRisk}
                rows={1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="detail-label">Evidencia del Riesgo Residual:</p>
              <FormTextField
                name="evidenceResidualRisk"
                control={control}
                error={errors.evidenceResidualRisk}
                rows={1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormRadioButton
                name="effective"
                control={control}
                label="Eficaz:"
                options={YesNoOptions}
                error={errors.effective}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default RiskStatusModal;
