import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const StyledInputAdornment = styled(InputAdornment)({
  cursor: "pointer",
});

const SearchBar = ({ name, control, label, onSearch, defaultValue, width }) => {
  const StyledTextField = styled(TextField)({
    width: width ? width : "auto",
    borderRadius: "6px",
    "& .MuiInputBase-input": {
      padding: "8.5px",
    },
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field }) => (
        <StyledTextField
          {...field}
          // label={'Buscar...'}
          InputProps={{
            startAdornment: (
              <StyledInputAdornment position="start">
                <SearchIcon
                  onClick={(e) => {
                    // field.onChange(e);
                    onSearch && onSearch(e.target.value);
                  }}
                />
              </StyledInputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;
