import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SimpleTable from "../tables/simpleTable";
import LaddaButton from "../buttons/laddaButton";
import { styled } from "@mui/material/styles";
import "./styles.css";

const panel = [
  { name: "Auto Evaluación", id: "0" },
  { name: "Evaluar a otros", id: "1" },
  { name: "Detalles de la Competencia", id: "2" },
  { name: "Informes", id: "3" },
  { name: "Material de Estudio", id: "4" },
];

const GeneralManagementPanel = ({ data, title, lastUpdate }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabContextStyled = styled(TabContext)(({ theme }) => ({
    "& .MuiTab-root": {
      color: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }));

  const CustomTab = styled(Tab)(({ theme }) => ({
    minWidth: "auto",
    padding: "35px 25px",
    textTransform: "initial",
    fontSize: "16px,",
    fontWeight: "900",
    fontFamily: "Lato",
    color: "#6A6AD8",
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#6A6AD8",
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "50px",
    border: `1px solid ${theme.palette.divider}`,
    borderTop: `0`,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    fontFamily: "Lato",
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    // borderTop: `1px solid ${theme.palette.divider}`,
  }));

  const CustomTabList = styled(TabList)(({ theme }) => ({
    backgroundColor: "#ffff",
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  }));

  const getChildren = (id) => {
    switch (id) {
      case "0":
        return (
          <CustomTabPanel value="0">
            <h2 className="gm-title">{data.autoEvaluation.title}</h2>
            <ul>
              {data.autoEvaluation.list.map((item) => {
                return <li className="li-item">{item.text}</li>;
              })}
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <LaddaButton
                variant="contained"
                backgroundColor={"#6A6AD8"}
                color={"#FFFF"}
                onClick={() => console.log("click")}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Tomar Auto Evaluación</p>
                </div>
              </LaddaButton>
            </div>
          </CustomTabPanel>
        );
      case "1":
        return (
          <CustomTabPanel value="1">
            <h2 className="gm-title">{data.studyMaterial.title}</h2>
            <SimpleTable
              text="Evaluar"
              button="text"
              data={data.studyMaterial.table.data}
              columns={data.studyMaterial.table.columns}
              scrollHeight={"300px"}
            />
          </CustomTabPanel>
        );
      case "2":
        return (
          <CustomTabPanel value="2">
            <h2 className="gm-title">{data.competitionDetails.title}</h2>
            <p className="content-text">{data.competitionDetails.text}</p>
            <h2 className="gm-title">{data.competitionDetails.titleII}</h2>
              <div className="evidence" style={{ backgroundColor: "#BBF7D0" }}>
                <p className="evidence-text">Nivel 3 a 4</p>
              </div>
          </CustomTabPanel>
        );
      case "3":
        return (
          <CustomTabPanel value="3">
            <h2 className="gm-title">{data.reports.title}</h2>
            <SimpleTable
              data={data.reports.table.data}
              columns={data.reports.table.columns}
            />
          </CustomTabPanel>
        );
      case "4":
        return (
          <TabPanel value="4">
            <h2 className="gm-title">{data.studyMaterial.title}</h2>
            <SimpleTable
              data={data.studyMaterial.table.data}
              columns={data.studyMaterial.table.columns}
            />
          </TabPanel>
        );
      default:
        break;
    }
  };

  return (
    <div className="gm-container">
      <div className="gm-header">
        <h1 className="gm-header-title">{title}</h1>
        <div className="gm-header">
          <p className="lastC-text">Última Actualización:</p>
          <p className="li-item">{lastUpdate}</p>
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContextStyled value={value}>
          <CustomBox sx={{ borderBottom: 1, borderColor: "divider" }}>
            <CustomTabList
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {panel.map((item) => {
                return <CustomTab label={item.name} value={item.id} />;
              })}
            </CustomTabList>
          </CustomBox>
          {panel.map((item) => {
            return getChildren(item.id);
          })}
        </TabContextStyled>
      </Box>
    </div>
  );
};

export default GeneralManagementPanel;
