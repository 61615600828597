import React, { useEffect, useState } from "react";

const PowerBiComponent = ({infoPowerBi}) => {

  return (
    <div className="VContainer j-center">
    <iframe
        title={infoPowerBi?.title}
        width="100%"
        height="850"
        src={infoPowerBi?.path}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default PowerBiComponent;
