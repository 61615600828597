import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import "./styles.css";
import HighchartsComponent from "../highchartsComponent";

const GraphicsCarousel = ({ data }) => {
  const [items, setItems] = useState([]);
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  useEffect(() => {
    setItems(data);
  }, [data]);

  const carouselTemplate = (items) => {
    return (
      <div className="carouselItem">
        <div>
          <HighchartsComponent
            options={items.chard(
              items.title,
              items.subtitle,
              items.yAxis,
              items.series,
              items.categories
            )}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <Carousel
        value={items}
        numVisible={2}
        numScroll={3}
        responsiveOptions={responsiveOptions}
        itemTemplate={carouselTemplate}
      />
    </div>
  );
};

export default GraphicsCarousel;
