import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

export const pieChard = (title, subtitle, yAxisText, series, categories) => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: title,
      align: "center",
    },
    subtitle: {
      text: subtitle,
      align: "left",
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      valueSuffix: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      dataLabels: {
        enabled: true,
        format: "{y}",
      },
    },
    series: series,
  };
};
