import React from "react";

const SimpleUnitCard = ({ unit, text, color, textColor }) => {
  return (
    <div
      className="VContainer align-center"
      style={{ borderRadius: "20px", backgroundColor: color ?? "#404040", margin: '15px 0' }}
    >
      <h1
        className="simple-unit-unit"
        style={{ color: textColor ?? "#FFFFFF" }}
      >
        {unit}
      </h1>
      <p className="simple-unit-text" style={{ color: textColor ?? "#FFFFFF" }}>
        {text}
      </p>
    </div>
  );
};

export default SimpleUnitCard;
