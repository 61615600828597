export const fixedPlacementColumns = (
    title,
    subtitle,
    yAxisText,
    series,
    categories
  ) => {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: title,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisText,
          style: {
            fontSize: "16px",
            color: "#666",
          },
        },
        labels: {
          overflow: "justify",
          style: {
            fontSize: "14px",
            color: "#666",
          },
        },
        gridLineWidth: 0,
      },
      legend: {
        shadow: false,
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },
      credits: {
        enabled: false,
      },
      series: series,
    };
  };
  