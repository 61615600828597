import React, { useState, useEffect, useCallback } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import ManagmentBanner from "../../components/managmentBanner";
import SimpleSelect from "../../components/selects/simpleSelect";
import FormTextField from "../../components/textField/formTextField";
import LaddaButton from "../../components/buttons/laddaButton";
import ModalComponent from "../../components/modals";
import SimpleTable from "../../components/tables/simpleTable";
import * as yup from "yup";
import {
  probabilityData,
  impactData,
  controlFrequency,
  controlType,
} from "../../constants/selectData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { icons } from "../../constants";
import { Grid } from "@mui/material";
import { getCookie } from "../../utils/getCookie";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import {
  callApiAsync,
  getCallProcess,
  getCallRiskType,
  postCreateRisk,
} from "../../services/apiService";
import { filterProcessByRole } from "./authorizeFxs";
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  riskType: yup.string().required("Este campo es obligatorio"),
  riskTitle: yup.string().required("Este campo es obligatorio"),
  description: yup
    .string()
    .required("Este campo es obligatorio")
    .max(500, "La descripción no puede exceder los 500 caracteres"),
  impactProcess: yup.string().required("Este campo es obligatorio"),
  causes: yup
    .string()
    .required("Este campo es obligatorio")
    .max(500, "Las causas no pueden exceder los 500 caracteres"),
  consequences: yup
    .string()
    .required("Este campo es obligatorio")
    .max(500, "Las consecuencias no pueden exceder los 500 caracteres"),
  impact: yup.string().required("Este campo es obligatorio"),
  probability: yup.string().required("Este campo es obligatorio"),
  controlType: yup.string().required("Este campo es obligatorio"),
  controlFrequency: yup.string().required("Este campo es obligatorio"),
});

const columnsHelp = [
  { field: "name", header: "Tipo de riesgo" },
  {
    field: "description",
    header: "Definición del tipo de riesgo",
    columnType: "fullText",
  },
];

const controlEffectivenessLevel = (levelItem) => {
  switch (levelItem) {
    case 1:
      return {
        class: "extreme-level",
        textClass: "extreme-level-text",
        text: "Inexsistente",
      };
    case 2:
    case 3:
    case 4:
      return {
        class: "low-level",
        textClass: "low-level-text",
        text: "Bajo",
      };
    case 5:
    case 6:
    case 7:
    case 8:
      return {
        class: "medium-level",
        textClass: "medium-level-text",
        text: "Medio",
      };
    case 9:
    case 10:
    case 11:
    case 12:
      return {
        class: "high-level",
        textClass: "high-level-text",
        text: "Alto",
      };
    default:
      return {
        class: "default-level",
        textClass: "default-level-text",
        text: "Sin Nivel",
      };
  }
};

const residualRiskLevel = (levelItem) => {
  if (!levelItem || levelItem === Infinity) {
    return {
      class: "default-level",
      textClass: "default-level-text",
      text: "Sin Nivel",
    };
  }
  // valor < 5 tolerable
  // valor <= 7 moderado
  // valor >= 8 importante
  // valor >= 12 inaceptable
  switch (true) {
    case levelItem < 5:
      return {
        class: "low-level",
        textClass: "low-level-text",
        text: "Tolerable",
      };
    case levelItem <= 7:
      return {
        class: "medium-level",
        textClass: "medium-level-text",
        text: "Moderado",
      };
    case levelItem >= 8 && levelItem < 12:
      return {
        class: "high-level",
        textClass: "high-level-text",
        text: "Importante",
      };
    case levelItem >= 12:
      return {
        class: "high-level",
        textClass: "high-level-text",
        text: "Nivel Alto",
      };
    default:
      return {
        class: "default-level",
        textClass: "default-level-text",
        text: "Sin Nivel",
      };
  }
};

const ControlEffectivenessComponent = ({ levelItem }) => {
  const {
    class: levelClass,
    textClass,
    text,
  } = controlEffectivenessLevel(levelItem);

  return (
    <div className={`level ${levelClass}`}>
      <p className={textClass}>{text}</p>
    </div>
  );
};

const ResidualRiskComponent = ({ levelItem }) => {
  const { class: levelClass, textClass, text } = residualRiskLevel(levelItem);

  return (
    <div className={`level ${levelClass}`}>
      <p className={textClass}>{text}</p>
    </div>
  );
};

const CreateRisk = () => {
  const userSesion = getCookie("sesion");
  const userData = useSelector((state) => state.userData.userData);
  const [openModal, setOpenModal] = useState(false);
  const [riskType, setRiskType] = useState([]);
  const [process, setProcess] = useState([]);
  const [riskLevel, setRiskLevel] = useState({ level: 0, type: "default" });
  const [controlEffectiveness, setControlEffectiveness] = useState(0);
  const [residualRisk, setResidualRisk] = useState(0);
  const [residualRiskType, setResidualRiskType] = useState(0);
  const [riskLeveltype, setRiskLeveltype] = useState(0);

  const navigate = useNavigate();

  const LevelComponent = ({ levelItem }) => {
    const { class: levelClass, textClass, text } = getRiskLevel(levelItem);

    return (
      <div className={`level ${levelClass}`}>
        <p className={textClass}>{text}</p>
      </div>
    );
  };

  const getRiskLevel = (levelItem) => {
    switch (levelItem.type) {
      case "sameLevel":
        if (levelItem.level === 1 || levelItem.level === 4) {
          setRiskLeveltype(1);
          return {
            class: "low-level",
            textClass: "low-level-text",
            text: "Nivel Bajo",
          };
        } else if (
          levelItem.level === 9 ||
          levelItem.level === 16 ||
          levelItem.level === 25
        ) {
          setRiskLeveltype(3);
          return {
            class: "high-level",
            textClass: "high-level-text",
            text: "Nivel Alto",
          };
        } else {
          setRiskLeveltype(0);
          return {
            class: "default-level",
            textClass: "default-level-text",
            text: "Sin Nivel",
          };
        }
      case "higherProbability":
        if (levelItem.level >= 2 && levelItem.level <= 3) {
          setRiskLeveltype(1);
          return {
            class: "low-level",
            textClass: "low-level-text",
            text: "Nivel Bajo",
          };
        } else if (levelItem.level === 4 || levelItem.level === 6) {
          setRiskLeveltype(2);
          return {
            class: "medium-level",
            textClass: "medium-level-text",
            text: "Nivel Medio",
          };
        } else if ([5, 8, 10, 12, 15, 20].includes(levelItem.level)) {
          setRiskLeveltype(3);
          return {
            class: "high-level",
            textClass: "high-level-text",
            text: "Nivel Alto",
          };
        } else {
          return {
            class: "default-level",
            textClass: "default-level-text",
            text: "Sin Nivel",
          };
        }
      case "higherImpact":
        if (levelItem.level >= 2 && levelItem.level <= 3) {
          setRiskLeveltype(1);
          return {
            class: "low-level",
            textClass: "low-level-text",
            text: "Nivel Bajo",
          };
        } else if (levelItem.level === 4 || levelItem.level === 6) {
          setRiskLeveltype(2);
          return {
            class: "medium-level",
            textClass: "medium-level-text",
            text: "Nivel Medio",
          };
        } else if ([5, 8, 10, 12, 15, 20].includes(levelItem.level)) {
          setRiskLeveltype(3);
          return {
            class: "high-level",
            textClass: "high-level-text",
            text: "Nivel Alto",
          };
        } else {
          return {
            class: "default-level",
            textClass: "default-level-text",
            text: "Sin Nivel",
          };
        }
      default:
        return {
          class: "default-level",
          textClass: "default-level-text",
          text: "Sin Nivel",
        };
    }
  };

  const createRisk = async (
    formData,
    riskLevel,
    controlEffectiveness,
    residualRisk,
    navigate
  ) => {
    const objRequest = {
      userId: userSesion.id_usuario,
      processId: formData.impactProcess,
      description: formData.description,
      title: formData.riskTitle,
      riskTypeId: formData.riskType,
      causes: formData.causes,
      consecuenses: formData.consequences,
      impact: formData.impact,
      probability: formData.probability,
      riskLevel: riskLevel,
      typeControl: formData.controlType,
      frecuencyControl: formData.controlFrequency,
      eficiencyControl: controlEffectiveness,
      residualRisk: residualRisk,
    };

    callApiAsync(
      () => postCreateRisk(objRequest),
      (data) => {
        const riskId = data.insertId;
        navigate("/riskAndOpportunityManagement/riskAdminGestion/riskDetails", {
          state: {
            rowData: {
              id: riskId,
            },
          },
        });
      }
    );
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onSubmit = (formData) => {
    createRisk(
      formData,
      riskLeveltype,
      controlEffectiveness,
      residualRiskType,
      navigate
    );
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const calculateRiskLevel = useCallback(() => {
    const formData = getValues();
    const probability = parseInt(formData.probability, 10);
    const impact = parseInt(formData.impact, 10);

    if (probability && impact) {
      const level = probability * impact;

      if (probability === impact) {
        setRiskLevel({ level, type: "sameLevel" });
      } else if (probability > impact) {
        setRiskLevel({ level, type: "higherProbability" });
      } else {
        setRiskLevel({ level, type: "higherImpact" });
      }
    }
  }, [getValues, setRiskLevel]);

  useEffect(() => {
    calculateRiskLevel();
  }, [calculateRiskLevel]);

  const calculateControlEffectiveness = useCallback(() => {
    const formData = getValues();
    const controlType = parseInt(formData.controlType, 10);
    const controlFrequency = parseInt(formData.controlFrequency, 10);

    if (controlType && controlFrequency) {
      const level = controlType * controlFrequency;
      setControlEffectiveness(level);
    }
  }, [getValues, setControlEffectiveness]);

  useEffect(() => {
    calculateControlEffectiveness();
  }, [calculateControlEffectiveness]);

  const calculateResidualRisk = useCallback(() => {
    const level = riskLevel.level / controlEffectiveness;
    let type = 0;
    if (level < 5) {
      type = 1;
    } else if (level <= 7) {
      type = 2;
    } else if (level >= 8 && level < 12) {
      type = 3;
    } else if (level >= 12) {
      type = 4;
    } else {
      type = 0;
    }
    setResidualRisk(level);
    setResidualRiskType(type);
  }, [riskLevel, controlEffectiveness]);

  useEffect(() => {
    calculateResidualRisk();
  }, [calculateResidualRisk]);

  const getRiskType = async () => {
    callApiAsync(getCallRiskType, (data) => {
      setRiskType(data);
    });
  };
  const getProcess = async () => {
    callApiAsync(getCallProcess, (data) => {
      var dataFiltered = filterProcessByRole(userData.roleList, data);
      setProcess(dataFiltered);
    });
  };

  useEffect(() => {
    getRiskType();
    getProcess();
  }, []);

  return (
    <div>
      <BreadCrumbComp />
      <ManagmentBanner />
      <div className="searchTitle riskAndOpportunityManagement-title">
        <h1>CREAR NUEVO RIESGO</h1>
      </div>

      <div className="create-form-container">
        <p className="createRist-title"> Riesgo</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="HContainer align-center">
                <p className="create-label">Tipo de Riesgo:</p>
                <div
                  className="pointer align-center j-center"
                  onClick={() => setOpenModal(true)}
                >
                  <img
                    className="btn-add-icon"
                    src={icons.infoExclamation}
                    alt="icon"
                    style={{ margin: "0 0 7px 0", width: "30px" }}
                  />
                </div>
              </div>
              <div>
                <SimpleSelect
                  name="riskType"
                  control={control}
                  options={riskType} //
                  error={errors.riskType}
                  hideLabel={true}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Título del Riesgo:</p>
              <FormTextField
                name="riskTitle"
                control={control}
                error={errors.riskTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Descripción:</p>
              <FormTextField
                name="description"
                control={control}
                isTextArea={true}
                error={errors.description}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Proceso de Impacto:</p>
              <SimpleSelect
                name="impactProcess"
                control={control}
                options={process}
                error={errors.impactProcess}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Causas:</p>
              <FormTextField
                name="causes"
                control={control}
                isTextArea={true}
                error={errors.causes}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="create-label">Consecuencias:</p>
              <FormTextField
                name="consequences"
                control={control}
                isTextArea={true}
                error={errors.consequences}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Impacto:</p>
              <SimpleSelect
                name="impact"
                control={control}
                options={impactData}
                error={errors.impact}
                onChange={calculateRiskLevel}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label">Probabilidad:</p>
              <SimpleSelect
                name="probability"
                control={control}
                options={probabilityData}
                error={errors.probability}
                onChange={calculateRiskLevel}
                hideLabel={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${riskLevel.level !== 0 ? "" : "hide"}`}
            >
              <div
                className={`HContainer align-center pillcontainer ${
                  riskLevel.level !== 0 ? "" : "hide"
                }`}
              >
                <p className="create-label">Nivel de Riesgo:</p>

                <LevelComponent levelItem={riskLevel} />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className={`${riskLevel.level !== 0 ? "" : "hide"}`}
            >
              <p className="create-label">Tipo de Control:</p>
              <SimpleSelect
                name="controlType"
                control={control}
                options={controlType}
                error={errors.controlType}
                onChange={calculateControlEffectiveness}
                hideLabel={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${riskLevel.level !== 0 ? "" : "hide"}`}
            >
              <p className="create-label">Frecuencia del Control:</p>
              <SimpleSelect
                name="controlFrequency"
                control={control}
                options={controlFrequency}
                error={errors.controlFrequency}
                onChange={calculateControlEffectiveness}
                hideLabel={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${riskLevel.level !== 0 ? "" : "hide"} ${
                controlEffectiveness !== 0 ? "" : "hide"
              }`}
            >
              <div className="HContainer align-center pillcontainer">
                <p className="create-label">Eficacia de Control:</p>
                <ControlEffectivenessComponent
                  levelItem={controlEffectiveness}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className={`${riskLevel.level !== 0 ? "" : "hide"} ${
                controlEffectiveness !== 0 ? "" : "hide"
              }`}
            >
              <div className="HContainer align-center pillcontainer">
                <p className="create-label">Riesgo residual:</p>
                <ResidualRiskComponent
                  levelItem={residualRisk} //// dividiendo nivel del riesgo/eficacia del control   valor  si
                  // valor < 5 tolerable
                  // valor <= 7 moderado
                  // valor >= 8 importante moderado
                  // valor >= 12 inaceptable
                />
              </div>
            </Grid>
          </Grid>
          <div className="j-center white-space">
            <LaddaButton
              width={"160px"}
              fontSize={"12px"}
              height={"40px"}
              variant="contained"
              type="submit"
              disabled={!isValid}
            >
              <p className="button_text">Crear Riesgo</p>
            </LaddaButton>
          </div>
        </form>
      </div>

      <ModalComponent open={openModal} handleClose={handleClose} title={""}>
        <SimpleTable data={riskType} columns={columnsHelp} />
      </ModalComponent>
    </div>
  );
};

export default CreateRisk;
