import React, { useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import EditModal from "./editModal";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";
import { opportunityState } from "../../../constants/selectData";

const TabDetails = ({ data, getOpportunityData, isEditionAvailable }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const openModalEditModal = () => {
    setOpenEditModal(true);
  };

  return (
    <div>
      <h2 className="gm-title">Estado:</h2>
      <p className="content-text">
        {data?.state
          ? opportunityState.find((item) => item.value === data.state)?.name
          : "" || "Creado"}
      </p>
      <h2 className="gm-title">Descripción:</h2>
      <p className="content-text">{formatTextWithLineBreaks(data?.description)}</p>
      <h2 className="gm-title">Beneficio:</h2>
      <p className="content-text">{formatTextWithLineBreaks(data?.benefit)}</p>
      <h2 className="gm-title">Plan de Acción:</h2>
      <p className="content-text">
        {formatTextWithLineBreaks(data?.actionPlan)}
      </p>
      <h2 className="gm-title">Impacto:</h2>
      <p className="content-text">{formatTextWithLineBreaks(data?.impact)}</p>
      <div className="j-center">
        {isEditionAvailable && (
          <LaddaButton
            variant="contained"
            onClick={() => {
              openModalEditModal();
            }}
          >
            <div className="HContainer align-rigth">
              <img
                className="btn-icon"
                src={icons.editWhite}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Editar</p>
            </div>
          </LaddaButton>
        )}
      </div>
      <EditModal
        opportunityData={data}
        openEditModal={openEditModal}
        handleCloseEditModal={handleCloseEditModal}
        getOpportunityData={getOpportunityData}
      />
    </div>
  );
};

export default TabDetails;
