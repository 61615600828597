import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bannerData: {
    code: "",
    lastDate: "",
    version: "",
  },
};

const utilsDataSlice = createSlice({
  name: "utilsData",
  initialState,
  reducers: {
    setBannerData: (state, action) => {
      state.bannerData = action.payload; // Deberías asegurarte de que action.payload sea un objeto con las propiedades correctas (code, lastDate, version)
    },
  },
});

export const { setBannerData } = utilsDataSlice.actions;
export default utilsDataSlice.reducer;
