import { Grid } from "@mui/material";
import React from "react";
import images from "../../constants/images";
import "./styles.css";
import { useSelector } from "react-redux";

const ManagmentBanner = () => {
  const renderData = useSelector((state) => state.utilsData.bannerData);

  return (
    <div className="HContainer managmentBanner align-center">
      <div>
        <img className="banner-img" src={images.bannerLogo} alt="icon" />
      </div>
      <div>
        <div className="bannerText-title-container">
          <p className="bannerText-title">
            FORMATO PARA REGISTRO DE RIESGOS, EVALUACIÓN SEMI-CUANTITATIVA,
            PLANES DE TRATAMIENTO Y MONITOREO DE RIESGOS
          </p>
          <p className="bannerText-title">IMPACTOS VS PROBABILIDAD</p>
        </div>
        <Grid container rowSpacing={2}>
          <Grid
            className="managmentBannerItem"
            item
            xs={11.8}
            sm={11.8}
            md={11.8}
          >
            <p className="bannerText">SISTEMA DE GESTIÓN DE CALIDAD</p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Código: {renderData.code}</p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Fecha: {renderData.date}</p>
          </Grid>
          <Grid className="managmentBannerItem" item xs={3.9} sm={3.9} md={3.9}>
            <p className="bannerText">Versión: {renderData.version}</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ManagmentBanner;
