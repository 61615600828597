import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./styles.css";

const ColorIndicator = () => {
  
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      color: "#333399",
      fontWeight: 700,
      padding : '15px 20px',
      fontSize: theme.typography.pxToRem(12),
      borderRadius: '10px',
      fontFamily: "Lato" 
    },
  }));

  return (
    <div className="color-indicator-container">
      <StyledTooltip title="Amenazas en el DOFA" followCursor>
        <div
          className="color-indicator-item ci-1"
          style={{ backgroundColor: "#FECDD3" }}
        ></div>
      </StyledTooltip>
      <StyledTooltip title="Debilidades en el DOFA" followCursor>
        <div
          className="color-indicator-item"
          style={{ backgroundColor: "#FED7AA" }}
        ></div>
      </StyledTooltip>
      <StyledTooltip title="Fortalezas en el DOFA" followCursor>
        <div
          className="color-indicator-item"
          style={{ backgroundColor: "#BBF7D0" }}
        ></div>
      </StyledTooltip>
      <StyledTooltip title="Oportunidades en el DOFA" followCursor>
        <div
          className="color-indicator-item"
          style={{ backgroundColor: "#BAE6FD" }}
        ></div>
      </StyledTooltip>
    </div>
  );
};

export default ColorIndicator;
