const images = {
    logoWhite: "/images/logo.png",
    mindMapBlueArrow: "/images/mind-map-blue-arrow.png",
    mindMapBlueArrowII: "/images/topbluearrow.svg",
    mindMapGreenArrow: "/images/grenArrow.svg",
    mindMapGreenContainerArrow: "/images/greenContainerArrow.svg",
    mindMapLongGreenArrow: "/images/longreenarrow.svg",
    mindMapYellowArrow: "/images/itemArrow.svg",
    
    arrowDown: "/images/flechita3-01.png",
    arrowDownUp: "/images/flechita4-01.png",
    arrowLeftRDown: "/images/flecha5.svg",
    arrowRightLDown: "/images/flecha7.svg",
    arrowDownLong: "/images/flecha6.svg",
    arrowRightLong: "/images/Vector21.png",
    conectLink: "/images/flecha1.svg",

    bannerLogo: '/images/seranestpharmalogo.svg'
  };
  
  export default images;
  