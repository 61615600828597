import http from "./httpService";
import { API_URL } from "../config";

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => {
        var error = "";
        if (err.response) {
          if (err.response.data) {
            error = err.response.data.error;
            console.log("error-response: ", err.response.data.error);
          } else {
            error = err.response;
            console.log("error-response: ", err.response);
          }
        } else if (err.request) {
          error = err.request;
          console.log("error-request: ", err.request);
        } else {
          error = err;
          console.log("error-error: ", err);
        }

        if (handleError) {
          console.log("handleError: -> ", error);
          handleError(error);
        }
      })
      .then(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      //   if (typeof error === "string") NotificationManager.error(error);
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const getAuthorityByUser = (userId) =>
  http.get(`${API_URL}/getAuthorityByUser/${userId}`);
export const getCallTimeFrameState = () =>
  http.get(`${API_URL}/getTimeFrameState/`);
export const getCallProcess = () => http.get(`${API_URL}/getProcess`);
export const getCallRiskType = () => http.get(`${API_URL}/getRiskType/1`);
export const postUpdateRisksAndOpportunities = (userId) =>
  http.post(`${API_URL}/updateRisksAndOpportunities`, userId);

export const postUpdateRiskTreatment = (data) =>
  http.post(`${API_URL}/updateRiskTreatment`, data);

export const postCreateOpportunity = (data) =>
  http.post(`${API_URL}/createOpportunity`, data);

export const postCreateDofa = (data) =>
  http.post(`${API_URL}/createDofa`, data);
export const postUpdateDofa = (data) =>
  http.post(`${API_URL}/updateDofa`, data);

export const postUpdateField = (data) =>
  http.post(`${API_URL}/updatefield`, data);
export const postUpdatePaiGoal = (data) =>
  http.post(`${API_URL}/updatePaiGoal`, data);
export const postUpdateActions = (data) =>
  http.post(`${API_URL}/updateActions`, data);
export const postSavePaiObservation = (data) =>
  http.post(`${API_URL}/savePaiObservation`, data);
export const postSaveEmployeeComment = (data) =>
  http.post(`${API_URL}/saveEmployeeComment`, data);
export const postAddAnswers = (data) =>
  http.post(`${API_URL}/addAnswers`, data);
export const postCreateUpdateReport = (data) =>
  http.post(`${API_URL}/createUpdateReport`, data);

export const postCreateRisk = (data) =>
  http.post(`${API_URL}/createRisk`, data);
export const postUpdateRiskDetail = (data) =>
  http.post(`${API_URL}/updateRiskDetail`, data);
export const postUpdateStatusRisk = (data) =>
  http.post(`${API_URL}/updateStatusRisk`, data);

export const postUpdateEvidence = (data) =>
  http.post(`${API_URL}/updateEvidence`, data);

export const postUpdateOpportunityDetail = (data) =>
  http.post(`${API_URL}/updateOpportunityDetail`, data);

export const postUpdateTimeFrameState = () =>
  http.post(`${API_URL}/updateTimeFrameState`);
export const postCreateDashboard = (data) =>
  http.post(`${API_URL}/createDashboard`, data);

export const postUpdateReport360Observations = (data) =>
  http.post(`${API_URL}/updateReport360Observations`, data);

export const getCallDofaReport = (userId, year, month) =>
  http.get(`${API_URL}/getDofaReport/${userId}/${year}/${month}`);
export const getCallJsonSettingVersion = () =>
  http.get(
    `${API_URL}/getJsonSettingVersion/RISK_OPPORTUNITY_MANAGEMENT_VERSION`
  );
export const getSettingById = (settingId) =>
  http.get(`${API_URL}/getSettingById/${settingId}`);

export const getCallDofaByUser = (userId, month, year, processId, state) =>
  http.get(
    `${API_URL}/getDofaByUser/${userId}/${month}/${year}/${processId}/${state}`
  );

export const getCallPaiObservationData = (id) =>
  http.get(`${API_URL}/getPaiObservationData/${id}`);

export const getCallPaiData = (userId) =>
  http.get(`${API_URL}/getPaiData/${userId}`);

export const getCallAutoEvaluation = (userId) =>
  http.get(`${API_URL}/getAutoEvaluation/${userId}`);

export const getCallPairSurvey = (userId) =>
  http.get(`${API_URL}/getPairSurvey/${userId}`);

export const getCallColabSurvey = (userId, year) =>
  http.get(`${API_URL}/getColabSurvey/${userId}/${year}`);

export const getCallManagerSurvey = (userId, year) =>
  http.get(`${API_URL}/getManagerSurvey/${userId}/${year}`);

export const getCallSurvey = (surveyId) =>
  http.get(`${API_URL}/getSurvey/${surveyId}`);

export const getCallActivitiesByCompetency = (userId) =>
  http.get(`${API_URL}/getActivitiesByCompetency/${userId}`);

export const getCallPositionList = () => http.get(`${API_URL}/getPositionList`);

export const getCallPowerBiById = (menuItemId) =>
  http.get(`${API_URL}/getPowerBiById/${menuItemId}`);

export const getCallPowerBiProcessList = (id) =>
  http.get(`${API_URL}/getPowerBiProcessList/${id}`);

export const getCallRisksList = (userId, year, month, processId) =>
  http.get(`${API_URL}/getRisksList/${userId}/${year}/${month}/${processId}`);

export const getCallRiskEvidenceList = (id) =>
  http.get(`${API_URL}/getRiskEvidenceList/${id}`);

export const getCallRisksResume = (userId, year, month) =>
  http.get(`${API_URL}/getRisksResume/${userId}/${year}/${month}`);

export const getCallOpportunitiesResume = (userId, year, month) =>
  http.get(`${API_URL}/getOpportunitiesResume/${userId}/${year}/${month}`);

export const getCallOpportunity = (opportunityId) =>
  http.get(`${API_URL}/getOpportunity/${opportunityId}`);

export const getCallRisk = (opportunityId) =>
  http.get(`${API_URL}/getRisk/${opportunityId}`);

export const getCallOpportunityEvidenceList = (id) =>
  http.get(`${API_URL}/getOpportunityEvidenceList/${id}`);

export const getCallOpportunityList = (userId, year, month, processId) =>
  http.get(
    `${API_URL}/getOpportunityList/${userId}/${year}/${month}/${processId}`
  );

export const getCallSelfAppraisal = (currentUserId) =>
  http.get(`${API_URL}/getSelfAppraisal/${currentUserId}`);

export const getCallEmployeesInMonitoring = (currentUserId, year) =>
  http.get(`${API_URL}/getEmployeesInMonitoring/${currentUserId}/${year}`);

export const getCallAllEmployeesInMonitoring = (userId) =>
  http.get(`${API_URL}/getAllEmployees/${userId}`);

export const getCallCurrentUser = (userId) =>
  http.get(`${API_URL}/getCurrentUser/${userId}`);

export const getCallManagerReport360GraphData = (userId) =>
  http.get(`${API_URL}/getManagerReport360GraphData/${userId}`);

export const getCallManagerTotal360GraphData = (userId) =>
  http.get(`${API_URL}/getManagerTotal360GraphData/${userId}`);

export const getCallSpecifyReport360GraphData = (userId) =>
  http.get(`${API_URL}/getSpecifyReport360GraphData/${userId}`);

export const getCallReport360Observations = (userId) =>
  http.get(`${API_URL}/getReport360Observations/${userId}`);

export const getCallOrganizationalReport360GraphData = (userId) =>
  http.get(`${API_URL}/getOrganizationalReport360GraphData/${userId}`);

export const getCallPowerBiDetailsByIdAndUserId = (
  idPowerBI,
  valueTab,
  userId,
  month,
  year
) =>
  http.get(
    `${API_URL}/getPowerBiDetailsByIdAndUserId/${idPowerBI}/${valueTab}/${userId}/${month}/${year}`
  );

export const postUpdateEvidenceOpportunity = (data) =>
  http.post(`${API_URL}/updateEvidenceOpportunity`, data);

export const getCallDofaGraph = (userId, yearFilter, fourMonthPeriodFilter) =>
  http.get(
    `${API_URL}/getDofaGraph/${userId}/${yearFilter}/${fourMonthPeriodFilter}`
  );

export const getCallRiskResumeGraph = (
  userId,
  yearFilter,
  fourMonthPeriodFilter
) =>
  http.get(
    `${API_URL}/getRiskResumeGraph/${userId}/${yearFilter}/${fourMonthPeriodFilter}`
  );

export const getCallOpportunityGraph = (userId, yearFilter, monthFilter) =>
  http.get(
    `${API_URL}/getOpportunityGraph/${userId}/${yearFilter}/${monthFilter}`
  );
