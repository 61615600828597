import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

export const horizontalColumn = (
  title,
  subtitle,
  series,
  categories,
  umbral
) => {
  return {
    chart: {
      type: "bar",
    },
    title: {
      text: title,
      align: "left",
    },
    subtitle: {
      text: subtitle,
      align: "left",
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
      labels: {
        style: { fontSize: "12px" },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      plotLines:
        umbral != null
          ? [
              {
                color: "red",
                width: 2,
                value: umbral.value,
                zIndex: 5,
                label: {
                  text: "",
                  align: "center",
                  x: -20,
                },
              },
            ]
          : [],
    },
    tooltip: {
      valueSuffix: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      dataLabels: {
        enabled: true,
        format: "{y}",
      },
    },
    series: series,
  };
};
