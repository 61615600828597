import React from "react";

export const formatTextWithLineBreaks = (text) => {
  if (!text) return "";
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const trasformStringToArray = (string, separator) => {
  const resultArray = string.split(separator);
  const trimmedArray = resultArray.map((line) => line.trim());

  return trimmedArray;
};
