import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    id: 0,
    authorityList: [],
    pageList: [],
    roleList: [],
  },
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserDataAuthority: (state, action) => {
      state.userData.authorityList = action.payload;
    },
    setUserDataRoleList: (state, action) => {
      state.userData.roleList = action.payload;
    },
  },
});

export const { setUserData, setUserDataAuthority, setUserDataRoleList } =
  userDataSlice.actions;
export default userDataSlice.reducer;
