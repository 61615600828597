import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  evaluationData: {
    totalAnswers: 0,
    surveyId: null,
  },
};

const evaluationSlice = createSlice({
  name: "evaluationData",
  initialState,
  reducers: {
    setEvaluationData: (state, action) => {
      state.evaluationData = action.payload;
    },
    resetEvaluationData: (state, action) => {
      state.evaluationData = {
        totalAnswers: 0,
        surveyId: null,
      };
    },
    setTotalAnswer: (state, action) => {
      state.evaluationData.totalAnswers = action.payload;
    },
  },
});

export const { setEvaluationData, resetEvaluationData , setTotalAnswer} =
  evaluationSlice.actions;
export default evaluationSlice.reducer;
