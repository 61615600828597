import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastNotification = ({ severity, summary, detail, showToast, setShowToast, position = "top-right" }) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (showToast) {
      toastRef.current.show({ severity, summary, detail });
      setShowToast(false); // Oculta el toast después de mostrarlo
    }
  }, [showToast, severity, summary, detail, setShowToast]);

  return (
    <Toast ref={toastRef} position={position} />
  );
};

export default ToastNotification;
