import React from "react";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import { dasboardBioMedicalBurnData } from "../../utils/burnData";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { months, years } from "../../constants/selectData";
import SimpleSelect from "../../components/selects/simpleSelect";
import FormTextField from "../../components/textField/formTextField";

const ManageDevice = () => {
  const { control: controlTable, getValues: getValuesTable } = useForm();

  const handleChangeTable = () => {
    const formData = getValuesTable();
  };

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>REQUERIMIENTOS</h1>
      </div>

      <div className="table-container">
        {/* <div className="searchTitle"> */}
        <div className="HContainer j-space-between">
          <h1 className="title-bio-details-chard">
            Inventario de Dispositivos Biomédicos
          </h1>
          <div className="HContainer align-center">
            <LaddaButton
              variant="contained"
              // width={"200px"}
              height={"32px"}
              onClick={() => {}}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.downloadIcon}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Descargar Inventario por Año</p>
              </div>
            </LaddaButton>
            <LaddaButton
              variant="contained"
              // width={"200px"}
              height={"32px"}
              onClick={() => {}}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.addPlus}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Agregar Dispositivo</p>
              </div>
            </LaddaButton>
          </div>
        </div>

        <br className="br-white-space" />

        <div className="mb-10">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4.2} md={4.2}>
                {/* <SimpleSelect
                  name="equipment"
                  control={controlTable}
                  label="Equipo"
                  options={months}
                  onChange={handleChangeTable}
                  // defaultValue={dateObj.month}
                /> */}
                <FormTextField
                  styledlabel="Equipo"
                  name="equipment"
                  control={controlTable}
                  // error={errors.opportunityTitle}
                />
              </Grid>
              <Grid item xs={12} sm={2.5} md={2.5}>
                <SimpleSelect
                  name="serieNumber"
                  control={controlTable}
                  label="Nro de Serie"
                  options={months}
                  onChange={handleChangeTable}
                  // defaultValue={dateObj.month}
                />
              </Grid>
              <Grid item xs={12} sm={2.5} md={2.5}>
                <SimpleSelect
                  name="mode"
                  control={controlTable}
                  label="Modelo"
                  options={months}
                  onChange={handleChangeTable}
                  // defaultValue={dateObj.month}
                />
              </Grid>
              <Grid item xs={12} sm={2.5} md={2.5}>
                <SimpleSelect
                  name="enginneer"
                  control={controlTable}
                  label="Modalidad"
                  options={months}
                  onChange={handleChangeTable}
                  // defaultValue={dateObj.month}
                />
              </Grid>
            </Grid>
          </form>
        </div>

        <div>
          <SimpleTable
            //data={data}
            data={dasboardBioMedicalBurnData.data}
            columns={dasboardBioMedicalBurnData.columns}
            // scrollHeight={"300px"}
            text={"Realizar Autoevaluación"}
            isPaginated={true}
            sortable
            buttons={[
              {
                type: "icon",
                icon: icons.editYellow,
                onClick: () => {
                  console.log("redirigir al PAI con el id");
                },
              },
              {
                type: "icon",
                icon: icons.eye,
                onClick: () => {
                  console.log("redirigir al PAI con el id");
                },
              },
            ]}
          />
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ManageDevice;
