export const tableASelfAppraisalBurnData = {
  columns: [
    { field: "employee", header: "Empleado", columnWidth: "350px" },
    { field: "position", header: "Cargo", columnWidth: "300px" },
    {
      field: "actionButtons",
      header: "",
      columnType: "actionSelfApraisalButtonsColumn",
    },
  ],
  data: [
    {
      id: "0",
      position: "Gerente",
      employee: "Lucas Rodriguez",
    },
  ],
};

export const tableBSelfAppraisalBurnData = {
  columns: [
    { field: "employee", header: "Empleados", columnWidth: "250px" },
    { field: "position", header: "Cargo", columnWidth: "180px" },
    { field: "area", header: "Área", columnWidth: "190px" },
    {
      field: "actionButtons",
      header: "",
      columnType: "actionSelfApraisalButtonsColumn",
    },
  ],
  data: [
    {
      id: "0",
      employee: "Juan Perez Díaz",
      position: "Visitador",
      level: "Senior",
      area: "Administración",
    },
    {
      id: "1",
      code: "Lucía Guiterrez",
      employee: "Gerente",
      position: "Sub Gerente",
      level: "Semi Senior",
      area: "Ventas",
    },
    {
      id: "2",
      employee: "Manuel Arana",
      position: "Sub Gerente",
      level: "Senior",
      area: "Administración",
    },
    {
      id: "3",
      employee: "Pedro Lopez",
      position: "Visitador",
      level: "Semi Senior",
      area: "Ventas",
    },
  ],
};

export const superior_rol_A_BurnData = {
  columns: [
    { field: "report", header: "Informe" },
    { field: "actionButtons", header: "", columnType: "actionButtons" },
  ],
  data: [
    {
      id: "0",
      report: "Informe 360 grados - Lucas Rodriguez",
    },
  ],
};

export const superior_rol_B_BurnData = {
  columns: [
    {
      field: "expander",
      header: "",
      columnType: "expander",
    },
    { field: "nameActivity", header: "Actividad", columnWidth: "450px" },
    { field: "competencyName", header: "Competencia", columnWidth: "150px" },
    { field: "priorityText", header: "Prioridad", columnWidth: "200px" },
    { field: "actionButtons", header: "", columnType: "actionButtons" },
  ],
  data: [
    {
      id: "0",
      text: "Material de estudio disponible ",
      competence: "Negociación",
      priority: "Obligatorio para Evaluación",
      materialTitle: "Primera actividad",
      material: [
        {
          type: "link",
          url: "https://www.youtube.com/watch?v=Kp7eSUU9oy8",
        },
        {
          type: "pdf",
          url: "/mediaTest/Ejemplo-de-descarga-pdf.pdf",
        },
        {
          type: "video",
          url: "/mediaTest/videoTest.mp4",
        },
        {
          type: "image",
          url: "/mediaTest/itsi49tbcci21.jpg",
        },
        {
          type: "gif",
          url: "/mediaTest/giphy.gif",
        },
      ],
    },
    {
      id: "2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull",
      competence: "Negociación",
      priority: "Obligatorio para Evaluación",
      materialTitle:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull",

      material: [
        {
          type: "link",
          url: "https://www.youtube.com/watch?v=Kp7eSUU9oy8",
        },
        {
          type: "pdf",
          url: "/mediaTest/TestPDFfile.pdf",
        },
      ],
    },
    {
      id: "3",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull",
      competence: "Negociación",
      priority: "Obligatorio para Evaluación",
      materialTitle: "material title",

      material: [
        {
          type: "image",
          url: "/mediaTest/itsi49tbcci21.jpg",
        },
        {
          type: "gif",
          url: "/mediaTest/giphy.gif",
        },
      ],
    },
    {
      id: "4",
      text: "Material de estudio disponible 4",
      competence: "Negociación",
      priority: "Obligatorio para Evaluación",
      materialTitle: "material Negociación",
      material: [
        {
          type: "link",
          url: "https://www.youtube.com/watch?v=Kp7eSUU9oy8",
        },
        {
          type: "pdf",
          url: "/mediaTest/ejemplo.pdf",
        },
        {
          type: "video",
          url: "/mediaTest/videoTest.mp4",
        },
      ],
    },
  ],
};

export const superior_rol_C_BurnData = {
  columns: [
    { field: "employee", header: "Empleados" },
    { field: "position", header: "Cargo" },
    {
      field: "comment",
      header: "Comentarios",
    },
    { field: "actionButtons", header: "", columnType: "actionButtons" },
  ],
  data: [
    {
      id: "0",
      employee: "Nazareno Aguirre",
      charge: "Director",
      comments:
        "1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "1",
      employee: "Florencia Rios",
      charge: "Gerente",
      comments:
        " 2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "2",
      employee: "Juan Perez",
      charge: "Gerente",
      comments:
        "3Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "3",
      employee: "Maria Lopez",
      charge: "Coordinador",
      comments:
        "4Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "4",
      employee: "Andres Forero",
      charge: "Director",
      comments:
        "5Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "5",
      employee: "Laura Soto",
      charge: "Gerente",
      comments:
        "6Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "6",
      employee: "Nazareno Aguirre",
      charge: "Director",
      comments:
        "7 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "7",
      employee: "Florencia Rios",
      charge: "Gerente",
      comments:
        " 8Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "8",
      employee: "Juan Perez",
      charge: "Gerente",
      comments:
        "9Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "9",
      employee: "Maria Lopez",
      charge: "Coordinador",
      comments:
        "10Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "10",
      employee: "Andres Forero",
      charge: "Director",
      comments:
        "11Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "11",
      employee: "Laura Soto",
      charge: "Gerente",
      comments:
        "12Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "12",
      employee: "Andres Forero",
      charge: "Director",
      comments:
        "13Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
    {
      id: "13",
      employee: "Laura Soto",
      charge: "Gerente",
      comments:
        "14Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
    },
  ],
};

export const dasboardBioMedicalBurnData = {
  columns: [
    { field: "item", header: "Item" },
    {
      field: "marketingPermit",
      header: "Permiso de Comercialización",
    },
    { field: "institution", header: "Descripción del Equipo" },
    { field: "brand", header: "Marca" },
    { field: "model", header: "Modelo" },
    { field: "serieNumber", header: "Nro. de Serie" },
    { field: "importer", header: "Importador" },
    { field: "location", header: "Ubicación" },
    { field: "actionButtons", columnType: "actionButtons" },
  ],
  data: [
    {
      item: "item",
      marketingPermit: "marketingPermit",
      institution: "institution",
      brand: "brand",
      model: "model",
      serieNumber: "serieNumber",
      importer: "importer",
      location: "location",
    },
  ],
};

export const MarketingPromotionManagementBurnData = [
  {
    id: 1,
    name: "Cumplimiento Real Vs Cuota",
    reportPbiId: 9999,
    analysis: null,
    commitment: null,
    reportMonthYear: null,
  },
  {
    id: 2,
    name: "Crecimiento 2024/2023",
    reportPbiId: null,
    analysis: null,
    commitment: null,
    reportMonthYear: null,
  },
  {
    id: 3,
    name: "Relacion Ventas/Enrolamiento Pacientes",
    reportPbiId: 1,
    analysis: "Test",
    commitment: "test",
    reportMonthYear: "2024-06",
  },
  {
    id: 4,
    name: "Relación médicos formuladores/Panel médico",
    reportPbiId: null,
    analysis: null,
    commitment: null,
    reportMonthYear: null,
  },
  {
    id: 5,
    name: "Enrolamiento/Pacientes según ventas",
    reportPbiId: null,
    analysis: null,
    commitment: null,
    reportMonthYear: null,
  },
  {
    id: 6,
    name: "Planeacion Mes Vs Mes",
    reportPbiId: null,
    analysis: null,
    commitment: null,
    reportMonthYear: null,
  },
];

export const surveyBurnData = [
  {
    competencyId: 1,
    competencyName: "Desarrollo de negocios",
    competencyDescription:
      "Crea y mantiene la visión del negocio a través de un pensamiento y actitud emprendedores. Desarrolla estrategias innovadoras que fortalecen la competitividad y sostenibilidad; se anticipa a los obstáculos y asume riesgos con responsabilidad para incrementar el valor agregado y la rentabilidad del negocio.",
    questionId: 1,
    question:
      "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    competencyId: 1,
    competencyName: "Desarrollo de negocios",
    competencyDescription:
      "Crea y mantiene la visión del negocio a través de un pensamiento y actitud emprendedores. Desarrolla estrategias innovadoras que fortalecen la competitividad y sostenibilidad; se anticipa a los obstáculos y asume riesgos con responsabilidad para incrementar el valor agregado y la rentabilidad del negocio.",

    questionId: 2,
    question:
      "2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    competencyId: 1,
    competencyName: "Desarrollo de negocios",
    competencyDescription:
      "Crea y mantiene la visión del negocio a través de un pensamiento y actitud emprendedores. Desarrolla estrategias innovadoras que fortalecen la competitividad y sostenibilidad; se anticipa a los obstáculos y asume riesgos con responsabilidad para incrementar el valor agregado y la rentabilidad del negocio.",

    questionId: 3,
    question:
      "3. Lorem i3nsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    competencyId: 1,
    competencyName: "Desarrollo de negocios",
    competencyDescription:
      "Crea y mantiene la visión del negocio a través de un pensamiento y actitud emprendedores. Desarrolla estrategias innovadoras que fortalecen la competitividad y sostenibilidad; se anticipa a los obstáculos y asume riesgos con responsabilidad para incrementar el valor agregado y la rentabilidad del negocio.",
    questionId: 4,
    question:
      "4. Lorem4 consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    questionId: 5,
    question:
      "5. Lo5tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    questionId: 6,
    question:
      "6. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
  {
    questionId: 7,
    question:
      "7. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ¿quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?",
    options: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
      { text: "7", value: 7 },
      { text: "8", value: 8 },
      { text: "9", value: 9 },
      { text: "10", value: 10 },
    ],
  },
];

export const paiBurnData = {
  //tabla pai
  tableBurnData: [
    {
      id: "0",
      name: "Doctor Orduz",
      comment:
        "sum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
      date: "10/01/2024",
    },
    {
      id: "1",
      name: "Don Humberto",
      comment:
        "ctetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
      date: "13/02/2024",
    },
    {
      id: "2",
      name: "Juan Perez",
      comment:
        "vamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
      date: "20/06/2024",
    },
    {
      id: "3",
      name: "Don Humberto",
      comment:
        "r sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
      date: "24/07/2024",
    },
  ],
  competencyData: [
    {
      //datos competencia
      competenciaId: 1,
      competencyName: "Desarrollo de negocios",
      description:
        "des1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      goals:
        "go Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      firstResultIndicator:
        "firstResultIndicatorectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      firstAction: "orem ipsum dolor sit amet, consectetur a",
      secondResultIndicator:
        "secondResultIndicator orem ipsum dolor sit amet, consectetur a",
      secondAction:
        "12 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      followUp: "Doctor Orduz, Don Humberto",
      startDate: "11/11/2011",
      followUpDate: "22/11/2011",
      lastFollowUp: "23/11/2011",
    },
    {
      //datos competencia
      competencyId: 2,
      competencyName: "Desarrollo de negocios",
      description:
        "des1 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      //tab pai
      goals:
        "go Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      firstResultIndicator:
        "firstResultIndicatorectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      firstAction: "orem ipsum dolor sit amet, consectetur a",
      secondResultIndicator:
        "secondResultIndicator orem ipsum dolor sit amet, consectetur a",
      secondAction:
        "12 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
      followUpBy: "Doctor Orduz, Don Humberto",
      starDate: "11/11/2011",
      updateDate: "22/11/2011",
      endDate: "23/11/2011",

      //tabla pai
      tableBurnData: [
        {
          id: "0",
          name: "Doctor Orduz",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
          date: "10/04/2024",
        },
        {
          id: "1",
          name: "Don Humberto",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
          date: "13/04/2024",
        },
        {
          id: "2",
          name: "Juan Perez",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
          date: "20/04/2024",
        },
        {
          id: "3",
          name: "Don Humberto",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ac egestas tortor. Pellentesque in urna odio. Aenean posuere, diam a feugiat pharetra, diam erat sagittis turpis.",
          date: "24/04/2024",
        },
      ],
    },
  ],
};

export const competencesReport360Specific = [
  { id: 1, name: "Desarrollo de negocios" },
  { id: 2, name: "Pensamiento estratégicos" },
  { id: 3, name: "Liderazgo" },
  { id: 4, name: "Negociación efectiva" },
  // -------
  // { id: 5, name: 'Orientacion a resultados' },
  // { id: 6, name: 'Toma de decisiones' },
  // { id: 7, name: 'Desarrollo de equipos' },
  // { id: 8, name: 'Capacidad de influencia' },
  // { id: 9, name: 'Analisis del entorno competitivo' },
  // { id: 10, name: 'Orientacion al cliente' },
  // { id: 11, name: 'Orientacion hacia la excelencia' },
  // { id: 12, name: 'Autogestión' },
  // { id: 13, name: 'Capacidad de motivación' },
  // { id: 14, name: 'Comunicación efectiva' },
  // { id: 15, name: 'Planeación y organización' },
  // { id: 16, name: 'Calidad, Salud ocupacional y mejoramiento continuo' },
  // { id: 17, name: 'Orientacion al logro' },
  // { id: 18, name: 'Trabajo en equipo' },
  // { id: 19, name: 'Disciplina' }
];

export const competencesReport360Organizational = [
  { id: 16, name: "Calidad, Salud ocupacional y mejoramiento continuo" },
  { id: 17, name: "Orientacion al logro" },
  { id: 18, name: "Trabajo en equipo" },
  { id: 19, name: "Disciplina" },
];

export const total360 = [
  { id: 1, name: "Par" },
  { id: 2, name: "Jefe" },
  { id: 3, name: "Cliente" },
  { id: 4, name: "Autoevaluador" },
  { id: 5, name: "Total" },
];
