import React, { useState } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import ActionPaiModal from "../firstActionTab/actionModal";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";

const SecondActionPaiTab = ({ data, getPaiData }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <div>
      <CustomTabPanel value="2">
        <h2 className="gm-title">Acciones:</h2>
        <div className="HContainer">
          <p className="content-text min-w-700">{formatTextWithLineBreaks(data?.action2)}</p>
          <div className="mv-20">
            <LaddaButton
              variant="contained"
              borderColor={"#6A6AD8"}
              backgroundColor={"#FFFF"}
              onClick={() => handleOpenModal()}
              width={"50px"}
            >
              <img className="btn-icon-20" src={icons.editBlue} alt="icon" />
            </LaddaButton>
          </div>
        </div>
        <h2 className="gm-title">Indicador de resultado:</h2>
        <p className="content-text min-w-700">{formatTextWithLineBreaks(data?.indicator2)}</p>
      </CustomTabPanel>
      <ActionPaiModal
        open={openModal}
        handleClose={handleClose}
        data={data}
        actionNumber={2}
        getPaiData ={getPaiData}
      />
    </div>
  );
};

export default SecondActionPaiTab;
