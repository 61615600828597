import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sesionData: 60,
};

const dataSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSesionData: (state, action) => {
      state.sesionData = action.payload;
    },
    resetSesionData: (state) => {
      state.sesionData = null;
    },
  },
});

export const {
    setSesionData,
    resetSesionData,
  } = dataSlice.actions;
export default dataSlice.reducer;
