import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../styles.css";
import {
  callApiAsync,
  postSavePaiObservation,
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  comment: yup.string().required("Este campo es obligatorio"),
  name: yup.string().required("Este campo es obligatorio"),
});

const ObservationPaiModal = ({
  open,
  handleClose,
  data,
  competenciaPaiId,
  getPaiObservationData,
  getPaiData
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    data ? setValue("name", data.name) : setValue("name", "");
    data ? setValue("comment", data.comment) : setValue("comment", "");
  };

  const clearData = () => {
    setValue("name", "");
    setValue("comment", "");
  };

  const updateForm = async (formData) => {
    // setToastMessage(`Se edito correctamente.`);
    // setShowToast(true);
    const objRequest = {
      id: data && data.id ? data.id : 0,
      competenciaPaiId: competenciaPaiId,
      name: formData.name,
      comment: formData.comment,
    };

    callApiAsync(
      () => postSavePaiObservation(objRequest),
      () => {
        getPaiObservationData();
        getPaiData();
        handleCloseModal();
      }
    );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(updateForm)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer form-modal">
          <Grid container justifyContent="center" spacing={2}>
            <div style={{ marginTop: "25px", width: "90%" }}>
              <Grid item xs={12}>
                <p className="detail-label mt-25">Nombre:</p>
                <FormTextField
                  name="name"
                  control={control}
                  isTextArea={true}
                  error={errors.name}
                  maxLength={200}
                  rows={1}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label mt-25">Comentario:</p>
                <FormTextField
                  name="comment"
                  control={control}
                  isTextArea={true}
                  error={errors.comment}
                  maxLength={500}
                  rows={2}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <p className="detail-label">Seguimiento por:</p>
                <FormTextField
                  name="followUp"
                  control={control}
                  error={errors.riskTitle}
                  maxLength={500}
                />
              </Grid> */}
            </div>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default ObservationPaiModal;
