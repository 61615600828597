import React from "react";

const MiniPill = ({ color, text, icon, iconPosition = false }) => {
  return (
    <div
      className={`miniPill-element pointer`}
      style={{ backgroundColor: color }}
      onClick={() => console.log("text", text)}
    >
      <p className="miniPill-element_text">{text}</p>
      {iconPosition !== true ? (
        <div className="icon-circle">
          <p>I</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MiniPill;
