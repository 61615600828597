// src/components/dofaComponent/index.js
import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LaddaButton from "../buttons/laddaButton";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../constants";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ModalComponent from "../modals";
import TextAreaForm from "../textArea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./styles.css";
import SimpleSelect from "../selects/simpleSelect";
import { stateDofa } from "../../constants/selectData";
import { getCookie } from "../../utils/getCookie";
import { Grid } from "@mui/material";
import FormTextField from "../textField/formTextField";
import {
  callApiAsync,
  postCreateDofa,
  postUpdateDofa,
} from "../../services/apiService";

const validationSchema = yup.object().shape({
  description: yup.string().required("Este campo es obligatorio"),
});

const DofaComponent = ({
  data,
  title,
  color,
  processId,
  month,
  year,
  getDofaByUser,
  filterdata,
  isAvailableToAdd,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentDofa, setCurrentDofa] = useState(null);
  const userSesion = getCookie("sesion");
  const navigate = useNavigate();
  const userId = userSesion.id_usuario;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const typeDofa = [
    { name: "Debilidades", value: 1 },
    { name: "Oportunidades", value: 2 },
    { name: "Fortalezas", value: 3 },
    { name: "Amenazas", value: 4 },
  ];

  const filteredType = typeDofa.find((type) => type.name === title);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: "",
    },
  });

  const navigationItem = useSelector(
    (state) => state.navigation.selectedMapItem
  );

  const getLevel = (int) => {
    const levels = [
      { text: "Nivel Bajo", color: "#22C55E" },
      { text: "Nivel Medio", color: "#F97316" },
      { text: "Nivel Alto", color: "#F43F5E" },
    ];
    return levels[int] || { text: "", color: "" };
  };

  const handleClose = () => {
    setOpenModal(false);
    setCurrentDofa(null);
    reset();
  };

  const handleOpenModal = (data = null) => {
    setCurrentDofa(data);
    setOpenModal(true);
    console.log(filteredType);
    setValue("description", data.description || "");
    setValue("state", data.state || "");
    setValue("type", filteredType.value || "");
  };

  const getButtons = (data) => (
    <div className="HContainer">
      {/* <div
        className="action-btn"
        style={{ margin: "0 7px" }}
        onClick={() => console.log(data)}
      >
        <img className="btn-icon" src={icons.link} alt="icon" />
      </div> */}
      {isAvailableToAdd &&
        currentMonth == data.month &&
        currentYear == data.year && (
          <div className="action-btn" onClick={() => handleOpenModal(data)}>
            <img className="btn-icon" src={icons.editBlack} alt="icon" />
          </div>
        )}
    </div>
  );

  const handleIdClick = () => {
    if (navigationItem === "risk-opportunity-matrix") {
      navigate("/dashboard/dofa/processDetails");
    }
  };

  const createDofa = async (formData) => {
    const objRequest = {
      userId: userId,
      processId: processId,
      description: formData.description,
      type: filteredType.value,
      year: year,
      month: month,
    };
    callApiAsync(
      () => postCreateDofa(objRequest),
      () => {
        getDofaByUser(filterdata.year, filterdata.month, filterdata.state);
        handleClose();
      }
    );
  };

  const updateDofa = async (formData) => {
    const objRequest = {
      id: currentDofa.id,
      description: formData.description,
      state: formData.state,
      type: formData.type,
    };
    callApiAsync(
      () => postUpdateDofa(objRequest),
      () => {
        getDofaByUser(filterdata.year, filterdata.month, filterdata.state);
        handleClose();
      }
    );
  };

  const onSubmit = (formData) => {
    if (currentDofa?.id) updateDofa(formData);
    else createDofa(formData);
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      color: "#333399",
      fontWeight: 700,
      padding: "15px 20px",
      fontSize: theme.typography.pxToRem(12),
      borderRadius: "10px",
      fontFamily: "Lato",
    },
  }));

  return (
    <div className="dofa_container">
      <div className="dofa_header">
        <p className="dofa_title">{title}</p>
        {isAvailableToAdd && (
          <LaddaButton variant="contained" onClick={() => handleOpenModal()}>
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.addPlus}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Agregar</p>
            </div>
          </LaddaButton>
        )}
      </div>
      <div className="dofa_body">
        {data.map((item) => (
          <StyledTooltip
            key={item.id}
            title={
              <>
                <p className="toolkit-title">Descripción:</p>
                <p className="toolkit-content">{item.description}</p>
              </>
            }
            followCursor
          >
            <div
              className="dofa_item"
              style={{ backgroundColor: color ?? "white" }}
            >
              <div className="text-dofa-container">
                <p className="comment_text">{item.description}</p>
              </div>
              <div>{getButtons(item)}</div>
            </div>
          </StyledTooltip>
        ))}
        <ModalComponent
          open={openModal}
          handleClose={handleClose}
          title={""}
          actions={
            <div className="HContainer j-center w-complete">
              <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
                <div className="HContainer align-center">
                  <p className="button_text">
                    {currentDofa === null ? "Guardar" : "Guardar Cambios"}
                  </p>
                </div>
              </LaddaButton>
            </div>
          }
        >
          <form className="VContainer form-modal m-20">
            <Grid justifyContent="center" container spacing={1}>
              <Grid item xs={12} md={12}>
                <p className="blueLabelForm">Descripción:</p>
                <FormTextField
                  name="description"
                  control={control}
                  isTextArea={true}
                  error={errors.description}
                  maxLength={500}
                  rows={2}
                />
              </Grid>
              {currentDofa === null ? (
                <></>
              ) : (
                <Grid justifyContent="center" container spacing={1.5}>
                  <Grid item xs={12} md={6}>
                    <div>
                      <p className="blueLabelForm">Estado:</p>
                      <SimpleSelect
                        name="state"
                        control={control}
                        label=""
                        options={stateDofa}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <p className="blueLabelForm">Tipo:</p>
                      <SimpleSelect
                        name="type"
                        control={control}
                        label=""
                        options={typeDofa}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </ModalComponent>
      </div>
    </div>
  );
};

export default DofaComponent;
