const NotAuthorized = () => {
  return (
    <>
      <div className="searchTitle riskAndOpportunityManagement-title">
        <h1>No tienes acceso al contenido de esta página</h1>
      </div>
    </>
  );
};

export default NotAuthorized;
