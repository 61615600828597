import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from "primereact/datatable";
import { Button } from 'primereact/button';
import {
  actionButtonsColumn,
  defaultColumn,
  tooltipColumn,
  titlesColumn,
  pillsColumn,
  redirectionColumn,
  percentageBtnColumn,
  upDownNumberColumn,
  actionSelfApraisalButtonsColumn,
  editButtonsColumn,
  hideColumn,
  urlColumn,
  columnRight,
  expanderColumn,
} from "../columns";
import "./styles.css";

const burnDataColumns = [
  { field: "code", header: "Code" },
  { field: "name", header: "Name" },
];

const burnData = [
  {
    id: "1000",
    code: "f230fh0g3",
    name: "Bamboo Watch",
  },
  {
    id: "1013",
    code: "fldsmn31b",
    name: "Grey T-Shirt",
  },
];

const SimpleTable = ({
  columns = burnDataColumns,
  data = burnData,
  buttons,
  scrollHeight,
  text,
  onClickftn,
  hideBtn,
  isPaginated,
  tableStyle,
  tooltipFragment,
  rowExpansionTemplate
}) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);

  const getColumnType = (item, i, arrayLength) => {
    const isLastItem = i === arrayLength.length - 1;

    const itemData = {
      index: i,
      lastItem: isLastItem,
      ...item,
    };

    switch (item.columnType) {
      case "titles":
        return titlesColumn(itemData);
      case "hide":
        return hideColumn(itemData);
      case "expander":
        return expanderColumn(itemData);
      case "url":
        return urlColumn(itemData);
      case "pills":
        return pillsColumn(itemData);
      case "tooltip":
        return tooltipColumn(itemData, tooltipFragment);
      case "redirection":
        return redirectionColumn(itemData);
      case "percentageBtn":
        return percentageBtnColumn(itemData);
      case "upDownNumber":
        return upDownNumberColumn(itemData);
      case "actionSelfApraisalButtonsColumn":
        return actionSelfApraisalButtonsColumn(
          itemData,
          buttons,
          onClickftn,
          text,
          hideBtn
        );
      case "editButtonsColumn":
        return editButtonsColumn(itemData, buttons, onClickftn, text, data);
      case "actionButtons":
        return actionButtonsColumn(itemData, buttons, onClickftn, text, data);
      case "columnRight":
        return columnRight(itemData);
      case "fullText":
        return defaultColumn(itemData, true);
      default:
        return defaultColumn(itemData);
    }
  };

  const tableBody = document.querySelector(".p-datatable-tbody");
  if (tableBody) {
    const rows = tableBody.querySelectorAll("tr");
    if (rows.length > 0) {
      rows[rows.length - 1].classList.add("last-row");
    }
  }


  const onRowExpand = (event) => {
    toast.current?.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
  };

  const onRowCollapse = (event) => {
    toast.current?.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  };

  const expandAll = () => {
    let _expandedRows = {};

    data.forEach((p) => (_expandedRows[`${p.id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
      <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text />
    </div>
  );

  // const rowExpansionTemplate = (data) => {
  //   return (
  //     <div className="p-3">
  //       <h5>Orders for {data.name}</h5>
  //     </div>
  //   );
  // };

  //   const allowExpansion = (rowData) => {
  //     return rowData.orders.length > 0;
  // };

  return (
    <div className="card">
      <DataTable
        // showGridlines
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}

        tableStyle={tableStyle}
        paginator={isPaginated}
        rows={10}
        scrollable
        scrollHeight={scrollHeight ?? "800px"}
        value={data}
        showGridlines
        emptyMessage={
          <span className="custom-empty-message">No hay datos disponibles</span>
        }
      >
        {/* <Column expander style={{ width: '5rem' }} /> */}

        {columns.map((col, i, arr) => getColumnType(col, i, arr))}
      </DataTable>
    </div>
  );
};

export default SimpleTable;
