import React from "react";
import HighchartsComponent from "../../../components/highchartsComponent";
import { verticalGroupColumns } from "../../../components/highchartsComponent/charts/verticalGroupColumns";

const ComparisonGraphSection = ({ type, data, umbral }) => {
  return (
    <div>
      {/* 
      type = 0 specific
      type = 1 organizational
      */}
      {type === 0 ? (
        <HighchartsComponent
          options={verticalGroupColumns(
            "Comparativa",
            "",
            "Cantidad",
            data.competenceComparisonGraphData.series,
            data.competenceComparisonGraphData.categories,
            umbral
          )}
        />
      ) : (
        <HighchartsComponent
          options={verticalGroupColumns(
            "Comparativa",
            "",
            "Cantidad",
            data.competenceComparisonGraphDataOrganizational.series,
            data.competenceComparisonGraphDataOrganizational.categories,
            umbral
          )}
        />
      )}
    </div>
  );
};

export default ComparisonGraphSection;
