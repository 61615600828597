import React, { useState, useEffect } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { months, years } from "../../../constants/selectData";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SimpleTable from "../../../components/tables/simpleTable";
import { icons } from "../../../constants";
import { getCurrentMonth, getCurrentYear } from "../../../utils/dateUtils";
import { getCookie } from "../../../utils/getCookie";
import { verticalGroupColumns } from "../../../components/highchartsComponent/charts/verticalGroupColumns";
import GraphSection from "../graphSection";
import { callApiAsync, getCallDofaReport } from "../../../services/apiService";

const DofaTabOrganizationContext = ({ data }) => {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [extraData, setExtraData] = useState({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });
  const { control, getValues, setValue } = useForm();
  const userSesion = getCookie("sesion");

  const columnsDofa = [
    { field: "ProcessName", header: "Procesos" },
    { field: "weaknesses", header: "Debilidades" },
    { field: "opportunities", header: "Oportunidades" },
    { field: "strengths", header: "Fortalezas" },
    { field: "threats", header: "Amenazas" },
    { field: "actions", header: "", columnType: "actionButtons" },
  ];

  useEffect(() => {
    setValue("year", getCurrentYear());
    setValue("month", getCurrentMonth());
    filterData(getCurrentYear(), getCurrentMonth());
  }, []);

  const handleChange = async () => {
    const formData = getValues();
    setExtraData(formData);
    await filterData(formData.year, formData.month);
  };

  const filterData = async (yearSelected, monthSelected) => {
    callApiAsync(
      () =>
        getCallDofaReport(userSesion.id_usuario, yearSelected, monthSelected),
      (data) => {
        setDataTable(data);
      }
    );
  };

  return (
    <CustomTabPanel value="0">
      <Grid justifyContent="flex-end" container spacing={1}>
        <form className="HContainer mr-10 mb-10">
          <Grid item xs={12} md={12}>
            <div className="mr-10">
              <SimpleSelect
                name="month"
                control={control}
                label="Mes"
                options={months}
                onChange={handleChange}
                // defaultValue={getDateObj.month}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <SimpleSelect
              name="year"
              control={control}
              label="Año"
              options={years}
              onChange={handleChange}
              // defaultValue={getDateObj.year}
            />
          </Grid>
        </form>
      </Grid>

      <SimpleTable
        data={dataTable}
        tableStyle={{ minWidth: "870px" }}
        columns={columnsDofa}
        buttons={[
          {
            type: "redirectData",
            icon: icons.eye,
            url: "/dashboard/dofa",
            extraData: extraData,
          },
        ]}
      />

      <GraphSection
        title={"DOFA"}
        filters={"dofa"}
        typeGraph={verticalGroupColumns}
      />
    </CustomTabPanel>
  );
};

export default DofaTabOrganizationContext;
