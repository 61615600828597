import React from "react";
import "./styles.css";
import images from "../../constants/images";

const ArrowElement = ({
  text,
  btns,
  type,
  width,
  height,
  textWidth,
  onClick,
  marginItem,
}) => {
  const getArrowStyle = (type) => {
    switch (type) {
      case "blue-arrow":
        return {
          width: width ? width : "auto",
          height: height ? height : "55px",
          backgroundImage: `url(${images.mindMapBlueArrow})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
      case "blue-arrow-II":
        return {
          width: width ? width : "auto",
          height: height ? height : "55px",
          backgroundImage: `url(${images.mindMapBlueArrowII})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
      case "long-green-arrow":
        return {
          width: width ? width : "auto",
          height: height ? height : "55px",
          backgroundImage: `url(${images.mindMapLongGreenArrow})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
      case "green-arrow":
        return {
          width: width ? width : "180px",
          height: height ? height : "170px",
          backgroundImage: `url(${images.mindMapGreenArrow})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: marginItem ? marginItem : "",
        };
      case "green-arrow-container":
        return {
          width: width ? width : "180px",
          height: height ? height : "170px",
          backgroundImage: `url(${images.mindMapGreenContainerArrow})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        };

      case "yellow-arrow":
        return {
          width: width ? width : "210px",
          height: height ? height : "55px",
          backgroundImage: `url(${images.mindMapYellowArrow})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
      default:
        return {
          width: "100px",
          height: "50px",
          // backgroundImage: `url(${images.mindMapBlueArrow})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
    }
  };

  return (
    <div
      className="blue-arrow-img"
      style={getArrowStyle(type)}
      onClick={() => (onClick ? onClick() : console.log("text", text))}
    >
      {/* <img
         className="blue-arrow-img"
        src={images.mindMapBlueArrow}
        alt="icon"
        // style={{ marginRight: "10px" }}
      /> */}
      <p
        className="arrow-element_text"
        style={{ width: textWidth ? textWidth : "110px" }}
      >
        {text}
      </p>
      {btns && btns.length > 0 && (
        <div className="arrow-element_buttons">
          {btns.map((btn) => (
            <div
              style={{
                width: '75px',
                height: '20px',
                backgroundImage: `url(${images.mindMapYellowArrow})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="arrow-element_buttons-text"
              // className="arrow-element_button"
              key={btn.text}
              onClick={() => {
                window.open(btn.url, "_blank");
              }}
            >
              {btn.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArrowElement;
