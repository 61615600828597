import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles.css";

const VerticalTab = ({
  children,
  panels,
  value,
  setValue,
  currentTab,
  setCurrentTab,
}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   if(panels && value){
  //     console.log('CurrentTab2-*-*-',panels.find(x=> x.id == value), value)
  //     setCurrentTab(panels.find(x=> x.id == value));
  //   }
  // }, [panels, value]);

  const CustomTabList = styled(TabList)(({ theme }) => ({
    backgroundColor: "#ffffff",
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    borderTopRightRadius: "15px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }));

  const TabContextStyled = styled(TabContext)(() => ({
    display: "flex",
    flexDirection: "row",
  }));

  const CustomTab = styled(Tab)(() => ({
    minWidth: "auto",
    padding: "15px 10px",
    textTransform: "initial",
    fontSize: "16px",
    fontWeight: "900",
    fontFamily: "Lato",
    color: "#6A6AD8",
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#6A6AD8",
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "20px",
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 0,
    borderBottomRightRadius: "15px",
    borderTopRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    borderTopLeftRadius: "15px",
    fontFamily: "Lato",
    flexGrow: 1,
  }));
  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <TabContextStyled value={value}>
        <Box sx={{ borderRight: 1, borderColor: "divider" }}>
          <CustomTabList
            onChange={handleChange}
            aria-label="Vertical Tabs Example"
            orientation="vertical"
          >
            {panels.map((item) => (
              <CustomTab label={item.name} value={item.id} key={item.id} />
            ))}
          </CustomTabList>
        </Box>
        {/* {panels.map((item) => (
          <CustomTabPanel value={item.id} key={item.id}>
            {item.content}
          </CustomTabPanel>
        ))} */}
        {
          <CustomTabPanel value={value} key={value}>
            {children}
          </CustomTabPanel>
        }
      </TabContextStyled>
    </Box>
  );
};

export default VerticalTab;
