import React from "react";

const PillComponent = ({ text, color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        height: "25px",
        borderRadius: "5px",
        padding: "10px 20px",
        width: "max-content",
        display: "flex",
        alignItems: "center",
      }}
    >
      <p className="text-pill-component">{text}</p>
    </div>
  );
};

export default PillComponent;
