import Cookies from "js-cookie";

export function getCookie(item) {
  const sesionCookie = Cookies.get(item);
  if (sesionCookie) {
    return JSON.parse(sesionCookie);
  } else {
    return null; 
  }
}
