import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMapItem: null,
  showManagementHeaderBtn: false,
};

const dataSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setSelectedMapItem: (state, action) => {
      state.selectedMapItem = action.payload;
    },
    resetSelectedMapItem: (state) => {
      state.selectedMapItem = null;
    },
    changeManagementHeaderBtnState: (state, action) => {
      state.showManagementHeaderBtn = action.payload;
    },
  },
});

export const { setSelectedMapItem, resetSelectedMapItem, changeManagementHeaderBtnState } = dataSlice.actions;
export default dataSlice.reducer;
