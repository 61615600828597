import React, { useState, useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";
import "./styles.css";

const items = [
  { text: "Ene", index: 1, percentage: "4.5" },
  { text: "Feb", index: 2, percentage: "5.5" },
  { text: "Mar", index: 3, percentage: "7" },
  { text: "Abr", index: 4, percentage: "8" },
  { text: "May", index: 5, percentage: "8.5" },
  { text: "Jun", index: 6, percentage: "9" },
  { text: "Jul", index: 7, percentage: "9.5" },
  { text: "Ago", index: 8, percentage: "10" },
  { text: "Sep", index: 9, percentage: "10.5" },
  { text: "Oct", index: 9, percentage: "10.5" },
  { text: "Nov", index: 9, percentage: "10.5" },
  { text: "Dic", index: 9, percentage: "10.5" },
];

const ProgressClientBar = ({ title, progress }) => {
  const [value, setValue] = useState(0);
  // const toast = useRef(null);
  // const interval = useRef(null);

  useEffect(() => {
    setValue(progress);
  }, [progress]);

  return (
    <div className="progress-client-bar">
      <h1 className="progress-label-title">{title}</h1>
      <ProgressBar value={value} className="custom-progress-bar"></ProgressBar>

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {items.map((item, index) => (
          <div key={index}><p className="progress-label-text">{item.text}</p><p className="progress-label-text">({item.percentage}) %</p></div>
        ))}
      </div>
    </div>
  );
};

export default ProgressClientBar;
