import React from "react";

export const AuthorizedComponent = ({ allowedAuthority, userAuthority, children }) => {
    const hasPermission = userAuthority.some(item => allowedAuthority.includes(item.id));
    return hasPermission ? <>{children}</> : null;
  };


  export const AuthorizedComponentByActions = ({ allowedAuthority, userAuthority, children }) => {
    const hasPermission = userAuthority.some(item => 
      allowedAuthority.includes(item.path) && item.write === 1
    );
  
    return hasPermission ? <>{children}</> : null;
  };