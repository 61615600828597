import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../styles.css";
import { callApiAsync, postUpdatePaiGoal } from "../../../services/apiService";

const validationSchema = yup.object().shape({
  goals: yup.string().required("Este campo es obligatorio"),
  followUpBy: yup.string().required("Este campo es obligatorio"),
});

const GoalPaiModal = ({ open, handleClose, data, getPaiData }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    setValue("goals", data?.goals);
    setValue("followUpBy", data?.followUpBy);
  };

  const clearData = () => {
    setValue("goals", "");
    setValue("followUpBy", "");
  };

  const updateForm = async (formData) => {
    const objRequest = {
      id: data.id,
      goals: formData.goals,
      followUpBy: formData.followUpBy,
    };

    callApiAsync(
      () => postUpdatePaiGoal(objRequest),
      () => {
        getPaiData();
        handleClose();
      }
    );
  };

  return (
    <div>
      {/* <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      /> */}
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(updateForm)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer form-modal">
          <Grid container justifyContent="center" spacing={2}>
            <div style={{ marginTop: "25px", width: "90%" }}>
              <Grid item xs={12}>
                <p className="detail-label mt-25">Metas:</p>
                <FormTextField
                  name="goals"
                  control={control}
                  isTextArea={true}
                  error={errors.goals}
                  maxLength={500}
                  rows={2}
                />
              </Grid>

              <Grid item xs={12}>
                <p className="detail-label">Seguimiento por:</p>
                <FormTextField
                  style={{ marginBottom: "20px" }}
                  name="followUpBy"
                  control={control}
                  error={errors.followUpBy}
                  maxLength={500}
                />
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default GoalPaiModal;
