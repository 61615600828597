import React, { useState } from "react";
import { icons } from "../../../constants";
import LaddaButton from "../../../components/buttons/laddaButton";
import ObservationsModal from "./observationsModal";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";
import {
  AuthorizedComponent,
  AuthorizedComponentByActions,
} from "../../../components/autorizedComponent";

const ObservationsTab = ({
  observationsData,
  getObservationsData,
  userIdSelected,
  userData,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="container_report_360">
        <p className="graph_title_report_360">Observaciones</p>
        <p>{formatTextWithLineBreaks(observationsData?.observations)}</p>
      </div>
      <div className="j-center">
        <AuthorizedComponentByActions
          allowedAuthority={["/360_Report"]}
          userAuthority={userData.pageList}
        >
          <LaddaButton variant="contained" onClick={handleOpenModal}>
            <div className="HContainer align-rigth">
              <img
                className="btn-icon"
                src={icons.editWhite}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Editar</p>
            </div>
          </LaddaButton>
        </AuthorizedComponentByActions>
      </div>
      <ObservationsModal
        open={openModal}
        handleClose={handleClose}
        observationsData={observationsData}
        getObservationsData={getObservationsData}
        userIdSelected={userIdSelected}
      />
    </>
  );
};

export default ObservationsTab;
