import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import "../styles.css";

const FormSelect = ({
  name,
  control,
  label,
  options,
  onChange,
  defaultValue,
  width,
  formLabel,
  error,
}) => {
  const StyledSelect = styled(Select)(() => ({
    width: width ? width : "auto",
    height: "40px",
    margin: "5px",
    borderRadius: "6px",
  }));

  const getOptionName = (value) => {
    const option = options.find((opt) => opt.value === value);
    return option ? option.name : "";
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <p className="select-form-label">{label}</p>
      <FormControl fullWidth error={Boolean(error)}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || "default"}
          render={({ field }) => (
            <StyledSelect
              {...field}
              labelId={`${name}-label`}
              id={name}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e.target.value);
              }}
              renderValue={(selected) => {
                if (!selected || selected === "default") {
                  return (
                    <MenuItem disabled value="default">
                      <p
                        className="inner-label-form-select"
                        style={{ marginLeft: "-20px" }}
                      >
                        {formLabel}
                      </p>
                    </MenuItem>
                  );
                }

                return getOptionName(selected);
              }}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        />
        {error && <p className="error-message">{error.message}</p>}
      </FormControl>
    </Box>
  );
};

export default FormSelect;
