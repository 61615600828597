import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pdfjs } from 'react-pdf';
import './styles.css';

const PdfViewer = ({fileUrl}) => {
    const defaultPlugin = defaultLayoutPlugin();

// /mediaTest/TestPDFfile.pdf

  return (
    <div className="pdf-container">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
      >
        <div
          style={{ border: "1px solid rgba(0, 0, 0, 0.3)", height: "750px" }}
        >
          <Viewer
            fileUrl={fileUrl}
            plugins={[
              // Registrar los plugins
              defaultPlugin,
              // Puedes añadir otros plugins aquí
            ]}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PdfViewer;
