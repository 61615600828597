import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadCrumbData: {
    month: 0,
    year: 0,
  },
};

const breadCrumbSlice = createSlice({
  name: "breadCrumData",
  initialState,
  reducers: {
    setBreadCrumData: (state, action) => {
      state.breadCrumbData = action.payload; 
    },
  },
});

export const { setBreadCrumData } = breadCrumbSlice.actions;
export default breadCrumbSlice.reducer;
