import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LaddaButton from "../../components/buttons/laddaButton";
import { Grid } from "@mui/material";
import { icons } from "../../constants";
import MainLayout from "../../layouts/mainLayout/index";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import PowerBiTab from "./tabs/powerBiTab";

const MarketingPromotionManagement = () => {
  const [powerBiTab, setPowerBiTab] = useState("1");
  let { state } = useLocation();

  const powerbiList = [
    { id: 1, name: "Urología" },
    { id: 2, name: "SISMED" },
    { id: 3, name: "Plataforma Médicos" },
  ];

  return (
    <div className="VContainer j-center">
      <MainLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              onClick={() => {
                setPowerBiTab("1");
              }}
              height={"60px"}
              borderRadius={"15px"}
              width={"100%"}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.pcMonitor}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Urología</p>
              </div>
            </LaddaButton>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              color={"#404040"}
              backgroundColor={"#F4D658"}
              onClick={() => {
                setPowerBiTab("2");
              }}
              width={"100%"}
              borderRadius={"15px"}
              height={"60px"}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.verificationList}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">SISMED</p>
              </div>
            </LaddaButton>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              onClick={() => {
                setPowerBiTab("3");
              }}
              height={"60px"}
              borderRadius={"15px"}
              width={"100%"}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.contract}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Plataforma Médicos</p>
              </div>
            </LaddaButton>
          </Grid>
        </Grid>
      </MainLayout>

      <TabContext value={powerBiTab}>
        {powerbiList.map((item, index) => {
          return (
            <TabPanel value={(index + 1).toString()}>
              <PowerBiTab powerBiData={item} />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default MarketingPromotionManagement;
