import { parseISO, format } from "date-fns";
import { es } from "date-fns/locale";

// Función para obtener el año actual
export function getCurrentYear() {
  return new Date().getFullYear();
}

// Función para obtener el mes actual
export function getCurrentMonth() {
  return new Date().getMonth() + 1;
}

export function getLastMonth() {
  return new Date().getMonth();
}

export const formatDateText = (isoString) => {
  const date = parseISO(isoString);

  // Obtener el nombre del día en español
  const dayName = new Intl.DateTimeFormat("es-ES", { weekday: "long" }).format(
    date
  );
  // Obtener el día del mes
  const dayNumber = format(date, "d", { locale: es });

  // Capitalizar el primer carácter del día
  const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

  return `${capitalizedDayName} ${dayNumber}`;
};

export const formatMonth = (isoString) => {
  const date = new Date(isoString);

  // Obtener el nombre del mes en español
  const monthName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
    date
  );

  // Capitalizar el primer carácter del mes
  const capitalizedMonthName =
    monthName.charAt(0).toUpperCase() + monthName.slice(1);

  return capitalizedMonthName;
};

export const formatYear = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  return year.toString();
};

export const getDateObj = () => {
  const fecha = new Date();
  const year = fecha.getFullYear();
  const month = fecha.getMonth() + 1;
  const day = fecha.getDate();
  return { day, month, year };
};

export const formatMonthYear = (date) => {
  if (date === "0000-00-00 00:00:00") return "";
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }

  const month = format(date, "MM");
  const year = format(date, "yyyy");
  const day = format(date, "dd");

  return `${day}/${month}/${year}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);
};

export const formatDateYMD = (dateString) => {
  //yyyy/MM/dd
  const formattedDate = format(new Date(dateString), "yyyy-MM-dd");
  return formattedDate;
};

// Devuelve true si el mes y año corresponden al actual.
export const getCurrentMonthYear = (year, month) => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  if (currentMonth === month && currentYear === year) return true;
  return false;
};

// Devuelve mes en texto
export const getMonthName = (monthNumber) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return months[monthNumber - 1];
};

export const getActualfourMonthPeriod = () => {
  const currentMonth = getCurrentMonth();
  switch (currentMonth) {
    case 1:
    case 2:
    case 3:
    case 4:
      return 1;
    case 5:
    case 6:
    case 7:
    case 8:
      return 2;
    case 9:
    case 10:
    case 11:
    case 12:
      return 3;
    default:
      return 1;
  }
};

export const getFourMonthPeriodName = (period) => {
  switch (period) {
    case 1:
      return ['Enero', 'Febrero', "Marzo", "Abril"];
    case 2:
      return ["Mayo", "Junio", "Julio", "Agosto",];
    case 3:
      return ["Septiembre", "Octubre", "Noviembre", "Diciembre",];
    default:
      return ['Enero', 'Febrero', "Marzo", "Abril"];
  }
};
