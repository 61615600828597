import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles.css";

export const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
  borderTop: `0`,
  borderBottomRightRadius: "15px",
  borderBottomLeftRadius: "15px",
  fontFamily: "Lato",
}));

const TabComponent = ({ panels }) => {
  const [value, setValue] = useState(panels[0].id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CustomTabList = styled(TabList)(({ theme }) => ({
    backgroundColor: "#ffff",
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  }));

  const TabContextStyled = styled(TabContext)(({ theme }) => ({
    "& .MuiTab-root": {
      color: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }));

  const CustomTab = styled(Tab)(() => ({
    // minWidth: "auto",
    padding: "35px 25px",
    textTransform: "initial",
    fontSize: "16px,",
    fontWeight: "900",
    fontFamily: "Lato",
    color: "#6A6AD8",
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#6A6AD8",
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "50px",
    border: `1px solid ${theme.palette.divider}`,
    borderTop: `0`,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    fontFamily: "Lato",
  }));

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContextStyled
        orientation="vertical"
        variant="scrollable"
        value={value}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <CustomTabList
         variant="fullWidth"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {panels.map((item) => (
              <CustomTab label={item.name} value={item.id} key={item.id} />
            ))}
          </CustomTabList>
        </Box>
        {panels.map((item) => (
          <CustomTabPanel value={item.id} key={item.id}>
            {item.content}
          </CustomTabPanel>
        ))}
      </TabContextStyled>
    </Box>
  );
};

export default TabComponent;
