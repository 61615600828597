import React, { useEffect, useState } from "react";
import FormTextField from "../../../components/textField/formTextField";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";
import { callApiAsync, postUpdateEvidence } from "../../../services/apiService";

yup.addMethod(yup.string, "serverOrUrl", function (message) {
  return this.test("serverOrUrl", message, function (value) {
    const { path, createError } = this;
    const urlRegex = /^(https?:\/\/)/;
    const serverRegex = /^[/\\]/;
    const noSpaces = /^\S*$/;
    return (
      ((urlRegex.test(value) || serverRegex.test(value)) &&
        noSpaces.test(value)) ||
      createError({
        path,
        message:
          message ||
          "Debe ser un enlace válido o una ruta de servidor que empiece por / o \\ y no tener espacios",
      })
    );
  });
});

const validationSchema = yup.object().shape({
  treatmentAction: yup.string().required("Este campo es obligatorio"),
  evidences: yup
    .string()
    .serverOrUrl(
      "Debe ser un enlace válido o una ruta de servidor que empiece por / y no tener espacios"
    )
    .required("Este campo es obligatorio"),
});

const MonthRegisterModal = ({
  riskId,
  riskData,
  open,
  handleClose,
  registrationId,
  isEditable,
  monthSelected,
  registrationData,
  getRisk,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (registrationId == 0) return;
    loadData();
  }, [registrationData, open]);

  const handleCloseModal = () => {
    clearData();
    reset({
      treatmentAction: registrationData.treatmentAction || "",
      evidence: registrationData.evidence || "",
    });
    handleClose();
  };

  const loadData = () => {
    setValue("treatmentAction", registrationData.treatmentAction);
    setValue("evidences", registrationData.evidence);
  };

  const clearData = () => {
    setValue("treatmentAction", "");
    setValue("evidences", "");
  };

  const updateEvidence = async (formData) => {
    const objRequest = {
      id: riskData.riskMonthId,
      state: riskData.state,
      evidence: formData.evidences,
      evidenceDescription: formData.treatmentAction,
    };

    callApiAsync(
      () => postUpdateEvidence(objRequest),
      () => {
        getRisk();
      }
    );
  };

  const onSubmit = async (formData) => {
    updateEvidence(formData);
    // const dataToSave = {
    //   riskId: riskId,
    //   treatmentAction: formData.treatmentAction,
    //   evidences: formData.evidences,
    // };
    handleClose();
    return;
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            {isEditable && (
              <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
                <div className="HContainer align-center">
                  <p className="button_text">Guardar Cambios</p>
                </div>
              </LaddaButton>
            )}
          </div>
        }
      >
        <form className="VContainer form-modal m-20">
          <Grid container justifyContent="center" spacing={2}>
            <div style={{ marginTop: "25px", width: "90%" }}>
              <Grid item xs={12}>
                <p className="detail-label">Acción de Tratamiento:</p>
                <FormTextField
                  name="treatmentAction"
                  control={control}
                  isTextArea={true}
                  error={errors.treatmentAction}
                  maxLength={500}
                  rows={1}
                  padding={"6.5px"}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <p className="detail-label mt-10">Evidencias:</p>
                <FormTextField
                  name="evidences"
                  control={control}
                  isTextArea={true}
                  error={errors.evidences}
                  maxLength={500}
                  rows={1}
                  padding={"6.5px"}
                  disabled={!isEditable}
                />
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default MonthRegisterModal;
