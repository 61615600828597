import React from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import ClientsCard from "../../components/cards/clientsCard";
import { Grid } from "@mui/material";
import SimpleUnitCard from "../../components/cards/simpleUnitCard";
import GraphicsCarousel from "../../components/carousel/graphicsCarousel";
import ProgressClientBar from "../../components/progressBar/progressClientBar";
import { icons } from "../../constants";
import { columnChartOptions } from "../../components/highchartsComponent/charts/columns";
import { overColumnChartOptions } from "../../components/highchartsComponent/charts/overColumns";

const topClientList = [
  "1- DROGUERIAS Y FARMACIAS CRUZ VERDE SAS",
  "2- AUDIFARMA SA ",
  "3 - FUNDACION SANTA FE DE BOGOTA ",
  "4 - NEUROMEDICA SAS",
  "5 - UROCLIN SAS",
  "1- DROGUERIAS Y FARMACIAS CRUZ VERDE SAS",
  "2- AUDIFARMA SA ",
  "3 - FUNDACION SANTA FE DE BOGOTA ",
  "4 - NEUROMEDICA SAS",
  "5 - UROCLIN SAS",
];

const topProductList = [
  "1- IALURIL PREFILL JER/PRELLENADA 50ML IALUADAPTER",
  "2- REMIFENTANILO 2 MG CJ X 5  (SP)",
  "3 - SERAFOL PROPOFOL 1% EMULSION 20 ML CAJA X5  (CP)",
  "4 - SERAFOL PROPOFOL 1% EMULSION 50 ML CAJA X1  (CP)",
  "5 - CASSETTE RESERVORIO DE 50ML REF 21-7301-24 (LM)",
  "1- IALURIL PREFILL JER/PRELLENADA 50ML IALUADAPTER",
  "2- REMIFENTANILO 2 MG CJ X 5  (SP)",
  "3 - SERAFOL PROPOFOL 1% EMULSION 20 ML CAJA X5  (CP)",
  "4 - SERAFOL PROPOFOL 1% EMULSION 50 ML CAJA X1  (CP)",
  "5 - CASSETTE RESERVORIO DE 50ML REF 21-7301-24 (LM)",
];

const carouselData = [
  {
    title: "Presupuesto de Venta vs. Venta Real",
    subtitle: "",
    chard: columnChartOptions,
    yAxis: "Miles de Millones",
    series: [
      {
        name: "Suma de Presupuesto_Valores",
        data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      },
      {
        type: "line",
        step: "center",
        name: "Suma de Ventas Real",
        data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
        marker: {
          lineWidth: 2,
          lineColor: "#6A6AD8",
          fillColor: "white",
        },
      },
    ],
    categories: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  },
  {
    title: "Presupuesto de Venta vs. Venta Real por Linea",
    subtitle: "",
    chard: overColumnChartOptions,
    yAxis: "Miles de Millones",
    series: [
      {
        name: "Suma de Presupuesto_Valores",
        color: "rgba(165,170,217,1)",
        data: [15, 7, 4, 4, 3, 2, 1],
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: "Ventas Reales",
        color: "rgba(126,86,134,.9)",
        data: [4, 3, 2, 4, 3, 2, 2],
        pointPadding: 0.4,
        pointPlacement: -0.2,
      },
    ],
    categories: [
      "Urologia",
      "Anestesicos",
      "Equipos Biomedicos",
      "Hipertension",
      "Suministros",
      "Hospitalaria",
      "Dolor",
    ],
  },
];

const Clients = () => {
  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>DASHBOARD CLIENTS</h1>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={12} md={6}>
          <ClientsCard
            url={"/clients/topClients"}
            title={"Top Clientes"}
            list={topClientList}
            icon={icons.tieManWhite}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <ClientsCard
            url={"/clients/topProducts"}
            title={"Top Productos"}
            list={topProductList}
            icon={icons.prescriptionBottle}
          />
        </Grid>
      </Grid>

      <ProgressClientBar title="Meta de Ventas por Mes (%)" progress={20} />

      <Grid container spacing={2} style={{ marginTop: "30px" }}>
        <Grid item xs={6} sm={12} md={6}>
          <SimpleUnitCard
            unit={"$1.282.457.478"}
            text={"Ventas Último Mes"}
            color={"#6A6AD8"}
            textColor={"#FFFFFF"}
          />
          <SimpleUnitCard
            unit={"$1.979.331.104,33"}
            text={"Presupuesto Mes Actual"}
            color={"#F4D658"}
            textColor={"#404040"}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <SimpleUnitCard
            unit={"$1.282.457.478"}
            text={"Total en Ventas"}
            color={"#6A6AD8"}
            textColor={"#FFFFFF"}
          />
          <SimpleUnitCard
            unit={"$1.979.331.104,33"}
            text={"Cumplimiento Total de ventas"}
            color={"#F4D658"}
            textColor={"#404040"}
          />
        </Grid>
      </Grid>

      <GraphicsCarousel data={carouselData} />
    </div>
  );
};

export default Clients;
