import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import SimpleSelect from "../../../components/selects/simpleSelect";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useNavigate } from "react-router-dom";
import { icons } from "../../../constants";
import { useForm } from "react-hook-form";
import { months, years } from "../../../constants/selectData";
import SimpleTable from "../../../components/tables/simpleTable";
import { stakedColumnChartOptions } from "../../../components/highchartsComponent/charts/stackedColumn";
import {
  getCurrentMonth,
  getCurrentYear,
  getMonthName,
} from "../../../utils/dateUtils";
import { getCookie } from "../../../utils/getCookie";
import GraphSection from "../graphSection";
import { useDispatch } from "react-redux";
import { setBreadCrumData } from "../../../redux/slices/breadCrumbSlice";
import { callApiAsync, getCallRisksResume } from "../../../services/apiService";
import { canEditByAuthority } from "./authorizeFunctions";

const RiskTab = ({ userData, userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [extraData, setExtraData] = useState({
    month: getCurrentMonth(),
    year: getCurrentYear(),
  });
  const [isEditable, setIsEditable] = useState(false);

  const userSesion = getCookie("sesion");
  const { control, getValues, setValue, watch } = useForm();

  const columnsRisk = [
    { field: "processName", header: "Procesos" },
    { field: "highLevel", header: "Nivel Alto" },
    { field: "mediumLevel", header: "Nivel Medio" },
    { field: "lowLevel", header: "Nivel Bajo" },
    { field: "total", header: "Total" },
    { field: "actions", header: "", columnType: "actionButtons" },
  ];

  const isEditableFn = () => {
    const canEditResult = canEditByAuthority(userData.pageList);
    if (canEditResult) {
      setIsEditable(true);
    }
  };

  useEffect(() => {
    setValue("year", getCurrentYear());
    setValue("month", getCurrentMonth());
    filterData(getCurrentYear(), getCurrentMonth());
    isEditableFn();
  }, []);

  const handleChange = async () => {
    const formData = getValues();
    setExtraData(formData);
    filterData(formData.year, formData.month);
  };

  const filterData = async (yearSelected, monthSelected) => {
    callApiAsync(
      () =>
        getCallRisksResume(userSesion.id_usuario, yearSelected, monthSelected),
      (data) => {
        setDataTable(data.table);
        setDataTotal(data.totalCount);
      }
    );
  };

  return (
    <CustomTabPanel value="1">
      <div className="HContainer mb-10">
        <div className="totalRiskContainer VContainer align-center">
          <p className="totalmonthrisk-number">{dataTotal}</p>
          <p className="totalmonthrisk-label">
            Total Riesgos en {getMonthName(watch("month"))} {watch("year")}
          </p>
        </div>

        <div className="creationButtonFilterContainer">
          <div>
            {isEditable && (
              <LaddaButton
                variant="contained"
                onClick={() => {
                  navigate("/riskAndOpportunityManagement/createRisk");
                }}
                borderRadius={"15px"}
                width={"100%"}
                height={"40px"}
                color={"#141313"}
                margin={"0 10px 20px 10px"}
                backgroundColor={"#EFCD00"}
              >
                <div className="HContainer align-center">
                  <img
                    className="btn-icon"
                    src={icons.exclamationboard}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  <p className="button_text">Crear Nuevo Riesgo</p>
                </div>
              </LaddaButton>
            )}
          </div>

          <div className=" info-form-container ">
            <form>
              <Grid container item xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <div className="mr-10">
                    <SimpleSelect
                      name="month"
                      control={control}
                      label="Mes"
                      options={months}
                      onChange={handleChange}
                      // defaultValue={getDateObj.month}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <SimpleSelect
                    name="year"
                    control={control}
                    label="Año"
                    options={years}
                    onChange={handleChange}
                    // defaultValue={getDateObj.year}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>

      <SimpleTable
        data={dataTable}
        columns={columnsRisk}
        tableStyle={{ minWidth: "870px" }}
        buttons={[
          {
            type: "redirectData",
            icon: icons.eye,
            url: "/riskAndOpportunityManagement/riskAdminGestion",
            extraData: extraData,
          },
        ]}
      />
      <GraphSection
        title={"DETALLES DE RIESGO"}
        filters={"risk"}
        typeGraph={stakedColumnChartOptions}
      />
    </CustomTabPanel>
  );
};

export default RiskTab;
