import React from "react";
import {  Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const hasGrantForPath = (pageList, path) => {
  const authority = pageList.find((item) => item.path === path);
  return authority ? authority.read === 1 : false;
};

const ProtectedRoute = ({ element, ...rest }) => {

  const userData = useSelector((state) => state.userData.userData);
  return hasGrantForPath(userData.pageList, rest.path) ? (
    element
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default ProtectedRoute;
