import React from "react";
import GeneralManagementPanel from "../../components/generalManagementPanel";

//----------------------------------- DATE -----------------------------------

const today = new Date();
const day = String(today.getDate()).padStart(2, "0");
const month = String(today.getMonth() + 1).padStart(2, "0");
const year = today.getFullYear();

const date = `${day}/${month}/${year}`;

const GeneralManagement = () => {
  /* ------------------------ TABS DATA ------------------------ */
  const businessDevelopment = {
    autoEvaluation: {
      title: "Requerimientos Nivel 5 a 6",
      list: [
        {
          text: "Indaga sobre los intereses y expectativas de sus interlocutores. ",
        },
        {
          text: "Indaga sobre el resultado que sus interlocutores desean obtener.  ",
        },
        {
          text: "Presenta sus puntos de vista e integra en ellos los de sus interlocutores.  ",
        },
        {
          text: "Logra acuerdos sostenibles susceptibles a ser revisados a corto y mediano plazo. ",
        },
      ],
    },
    evaluateOthers: {
      title: "Evaluaciones disponibles",
      table: {
        columns: [
          { field: "employee", header: "Empleado" },
          { field: "position", header: "Cargo" },
        ],
        data: [
          {
            id: 1,
            employee: "Lucas Rodriguez",
            position: "Visitador",
          },
          {
            id: 1,
            employee: "Florencia Ríos",
            position: "Gerente",
          },
          {
            id: 1,
            employee: "Juan Perez",
            position: "Gerente",
          },
        ],
      },
    },
    competitionDetails: {
      title: "Descripción:",
      text: "Establece acuerdos y realiza transacciones con actores complejos del sistema, con la finalidad de obtener beneficios importantes para las dos partes del proceso negociador. Encuentra soluciones creativas para integrar los intereses y motivaciones de las partes, generando acuerdos altamente satisfactorios y novedosos, comprometiendo a todos en el seguimiento y cumplimiento de tales acuerdos. ",
      titleII: "Señales de evidencia: ",
      level: 0,
    },
    reports: {
      title: "Informes de Evaluación",
      table: {
        columns: [{ field: "reports", header: "Informe" }],
        data: [
          {
            id: 1,
            reports: "Informe de Auto Evaluación",
          },
          {
            id: 1,
            reports: "Informe de Evaluación de Compañeros",
          },
        ],
      },
    },
    studyMaterial: {
      title: "Material de estudio disponible para Nivel 5 a 6",
      table: {
        columns: [
          { field: "text", header: "Texto" },
          { field: "priority", header: "Prioridad" },
        ],
        data: [
          {
            id: 1,
            text: "Informe de Auto Evaluación A",
            priority: "No es obligatorio para Evaluación",
          },
          {
            id: 1,
            text: "Informe de Auto Evaluación B",
            priority: "Obligatorio para Evaluación",
          },
          {
            id: 1,
            text: "Informe de Auto Evaluación C",
            priority: "Obligatorio para Evaluación",
          },
        ],
      },
    },
  };

  /* ------------------------ ------------------------ */

  return (
    <div>
      <GeneralManagementPanel
        title={"Competencia - Desarrollo de Negocios"}
        data={businessDevelopment}
        lastUpdate={date}
      />

      <GeneralManagementPanel
        title={"Competencia - Pensamiento Estratégico"}
        data={businessDevelopment}
        lastUpdate={date}
      />

      <GeneralManagementPanel
        title={"Competencia - Liderazgo"}
        data={businessDevelopment}
        lastUpdate={date}
      />

      <GeneralManagementPanel
        title={"Competencia - Negociación efectiva"}
        data={businessDevelopment}
        lastUpdate={date}
      />
    </div>
  );
};

export default GeneralManagement;
