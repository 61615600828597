import React, { useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import LaddaButton from "../../components/buttons/laddaButton";
import HighchartsComponent from "../../components/highchartsComponent";
import SimpleSelect from "../../components/selects/simpleSelect";
import SearchBar from "../../components/searchBar";
import SimpleTable from "../../components/tables/simpleTable";
import { columnChartOptions } from "../../components/highchartsComponent/charts/columns";
import { months, years } from "../../constants/selectData";
import { useForm } from "react-hook-form";
import { getDateObj } from "../../utils/dateUtils";
import { icons } from "../../constants";
import "./styles.css";
import { Link } from "react-router-dom";

const burnDataChard = {
  title: "Presupuesto de Venta vs. Venta Real",
  subtitle: "",
  chard: columnChartOptions,
  yAxis: "Miles de Millones",
  series: [
    {
      name: "Suma de Presupuesto_Valores",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
    },
    {
      type: "line",
      step: "center",
      name: "Suma de Ventas Real",
      data: [1.1, 1.2, 1.4, 2, 2.2, 2.4, 2.5, 2.5, 2.6, 2.7, 2.7],
      marker: {
        lineWidth: 2,
        lineColor: "#6A6AD8",
        fillColor: "white",
      },
    },
  ],
  categories: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
};

const dataTable = [
  {
    id: "0",
    customerName: "ANESTESIOLOGIA VETERINARIA COLOMBIA SAS",
    budget: "10.008.000",
    sales: "240712500",
    strengths: "100",
    linesNumber: "2",
  },
  {
    id: "1",
    customerName: "ASOCIACION PROFAMILIA",
    budget: "53.550.000",
    sales: "230536500",
    strengths: "100",
    linesNumber: "1",
  },
  {
    id: "2",
    customerName: "AUDIFARMA SA",
    budget: "3.174.150.000",
    sales: "130357000",
    strengths: "100",
    linesNumber: "1",
  },
  {
    id: "3",
    customerName: "AVIDANTI SAS",
    budget: "15.135.000",
    sales: "128190000",
    strengths: "100",
    linesNumber: "2",
  },
  {
    id: "4",
    customerName: "BONNE SANTE GROUP SAS",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "79",
    linesNumber: "1",
  },
  {
    id: "5",
    customerName: "CENTRO OFTALMOLOGICO OLSABE SAS",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "78",
    linesNumber: "1",
  },
  {
    id: "6",
    customerName: "CIRULASER ANDES SA",
    budget: "17.040.000",
    sales: "240712500",
    strengths: "70",
    linesNumber: "1",
  },
];

const TopClients = () => {
  const [visible, setVisible] = useState(false);
  const { control, getValues } = useForm();
  const dateObj = getDateObj();

  const handleChange = () => {
    const formData = getValues();
    console.log(formData);
  };

  const topClientsTableColums = [
    {
      field: "customerName",
      header: "Nombre del Cliente",
      columnType: "redirection",
      url: "/clients/topClients/details",
    },
    { field: "budget", header: "Presupuesto" },
    { field: "sales", header: "Ventas", columnType: "upDownNumber" },
    {
      field: "strengths",
      header: "Cumplimiento %",
      columnType: "percentageBtn",
    },
    { field: "linesNumber", header: "Cant. Líneas" },
  ];

  const alertClients = [
    { id: 0, name: "CENTRO OFTALMOLOGICO OLSABE SAS", percentage: "90" },
    { id: 0, name: "CENTRO UROLOGICO FOSCAL SAS", percentage: "90" },
    { id: 0, name: "CIRULASER ANDES SA", percentage: "88" },
    { id: 0, name: "CIRUSABANA S.A CENTRO QUIRURGICO...", percentage: "89" },
    { id: 0, name: "CLINICA ASOTRAUMA SAS", percentage: "88" },
    { id: 0, name: "CLINICA BUENOS AIRES S.A.S", percentage: "85" },
    { id: 0, name: "CLINICA CASTELLANA S.A.S", percentage: "85" },
  ];

  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">LISTADO DE CLIENTES</h1>
        <div className="white-space" />
        <div className="HContainer">
          <div className="VContainer" style={{ flexDirection: "row-reverse" }}>
            <LaddaButton
              variant="contained"
              color={"black"}
              backgroundColor={"#F4D658"}
              onClick={() => setVisible(!visible)}
            >
              <div className="HContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.blackBell}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Alertas Clientes</p>
              </div>
            </LaddaButton>
            <div
              className={`container-client-alert animated-div-container ${
                visible ? "visible" : ""
              }`}
            >
              <p className="container-client-alert-title">
                Clientes con Alertas en Presupuestos:
              </p>
              {alertClients.map((x) => (
                <div className="client-alert-item">
                  <Link
                    className="column-link alert-item-name"
                    to={{
                      pathname: "",
                      state: x,
                    }}
                  >
                    {x.name}
                  </Link>
                  <p className="alert-item-percentage">{x.percentage}%</p>
                  <div className="eye-btn" onClick={() => console.log("click")}>
                    <img className="btn-icon" src={icons.eye} alt="eye" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <HighchartsComponent
          options={burnDataChard.chard(
            burnDataChard.title,
            burnDataChard.subtitle,
            burnDataChard.yAxis,
            burnDataChard.series,
            burnDataChard.categories
          )}
        />
      </div>
      <div className="white-space" />
      <div className="HContainer">
        <form
          style={{ display: "flex", display: "flex", alignItems: "center" }}
        >
          <SearchBar name="search" control={control} onSearch={handleChange} />
          <SimpleSelect
            name="month"
            control={control}
            label="Mes"
            options={months}
            onChange={handleChange}
            defaultValue={dateObj.month}
          />
          <SimpleSelect
            name="quarter"
            control={control}
            label="Trimestre"
            options={months}
            onChange={handleChange}
            defaultValue={dateObj.month}
          />
          <SimpleSelect
            name="year"
            control={control}
            label="Año"
            options={years}
            onChange={handleChange}
            defaultValue={dateObj.year}
          />
        </form>
      </div>
      <div className="white-space" />
      <SimpleTable
        data={dataTable}
        columns={topClientsTableColums}
        // buttons={[
        //   {
        //     type: "icon",
        //     icon: icons.eye,
        //     onClick: () => navigate("/dashboard/dofa"),
        //   },
        // ]}
      />
    </div>
  );
};

export default TopClients;
