import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LaddaButton from "../buttons/laddaButton";
import zIndex from "@mui/material/styles/zIndex";
import { styled } from "@mui/material";

const ModalComponent = ({ open, handleClose, title, children, actions }) => {
  const DialogStyled = styled(Dialog)(({ theme }) => ({
    //zIndex: { modal: 1000 },
  }));

  return (
    <Dialog
      maxWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
    >
      {title === "" ? (
        <></>
      ) : (
        <DialogTitle id="modal-title">
          <h2 className="gm-title ">{title}</h2>
        </DialogTitle>
      )}
      <DialogContent style={{ paddingTop: "10px" }}>{children}</DialogContent>
      <DialogActions>
        {actions ? (
          actions
        ) : (
          <LaddaButton variant="contained" onClick={handleClose}>
            <div className="HContainer align-center">
              <p className="button_text">Cerrar</p>
            </div>
          </LaddaButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalComponent;
