import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import TabComponent from "../../components/tabComponent";
import "./styles.css";
import { getCookie } from "../../utils/getCookie";
import DofaTabOrganizationContext from "./dofaTab";

const Dashboard = () => {
  const BurnDataDofa = {};
  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;

  const tabData = [
    {
      name: "DOFA",
      id: "0",
      content: <DofaTabOrganizationContext data={{}} userId={userId} />,
    },
    ,
  ];

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle bm-50">
      </div>
      <TabComponent panels={tabData} />
    </div>
  );
};

export default Dashboard;
